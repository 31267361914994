import React, { useState, forwardRef, useContext, useEffect, useRef, Suspense } from "react";
import { client } from 'websocket';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import * as Sentry from "@sentry/react";

import Loading from "../../components/Loading";
import usePrevious from "../../components/usePrevious"

import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { RidesView, MapView, useWindowSize, DeviceReportView, RidesEdit } from '../../components';
import { UserContext } from "../../components/userDetails";
import { newLayots, checkMount, filterLayouts, margin, breakpoints, cols, HeaderGrid, calcMaxH, rowHeight } from "../gridLayout";
import groupDataRides from "../../components/RidesView/groupDataRides";
import { layoutRidesDefault, layoutNew } from "./layoutRides";
import { max } from "date-fns";

// import de from "date-fns/esm/locale/de/index.js";

const ResponsiveGridLayout = WidthProvider(Responsive);

var mapWidth, mapHeight, ridesHeight, ridesWidth, deviceReportHeight, deviceReportWidth;

const GridItemComponent = forwardRef(({ style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props }, ref) => {
  if (props.max === props.id) {
    style.height = props.heightWindow + 'px';
    style.width = "99%";
    style.transform = 'translate(5px, 5px)'
    style.transition = 'all 200ms ease'
  }
  else {
    if (props.max) style.display = 'none';
  }

  if (props.id === 'RidesView') {
    ridesHeight = style.height.slice(0, -2);
    ridesWidth = style.width.slice(0, -2);
  }

  if (props.id === 'MapView') {
    mapHeight = style.height.slice(0, -2);
    mapWidth = style.width.slice(0, -2);
  }

  if (props.id === 'DeviceReport') {
    deviceReportHeight = style.height.slice(0, -2);
    deviceReportWidth = style.width.slice(0, -2);
  }

  return (
    <div style={{ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
      {children}
    </div>
  );
})


export default function Rides({ forceUpdate, widgetOnDrop, setWidgetOnDrop, defaultLayout }) {
  const { costumer, dev, driv } = useContext(UserContext)
  const [devices, setDevices] = useState(dev);
  const [devFocus, setDevFocus] = useState(false);
  const prevDevFocus = usePrevious(devFocus);
  const [drivers, setDrivers] = useState(driv)
  const [drivFocus, setDrivFocus] = useState(typeof driv === 'number' ? driv : null)
  // in rides it is origin rides from server
  const [rides, setRides] = useState(false);
  const [ridesDaily, setRidesDaily] = useState(false);
  const [ridesIds, setRidesIds] = useState([]);
  const [ridesIdsFocus, setRidesIdsFocus] = useState([]);
  const [ridesPoints, setRidesPoints] = useState(false);
  const [ridesPointsMemo, setRidesPointsMemo] = useState(false);
  const [stats, setStats] = useState(false)
  const [report, setReport] = useState(false);
  // const loading = useRef(false);
  const { t } = useTranslation('translation', { keyPrefix: 'widget' });
  const navigate = useNavigate();
  const location = useLocation();
  const loadingCurRideTime = useRef(false);
  const waitingGetRide = useRef(false);
  const refRender = useRef(null);
  const [loadingRide, setLoadingRide] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [date, setDate] = useState(false)
  const [editRides, setEditRides] = useState(false);
  const [layout, setLayout] = useState();
  const [itemMax, setItemMax] = useState();

  document.title = t('rides')

  if (!devices) {
    WsSend({ method: 'getDevices', oid: 4, restriction: 'view_history' });
    // loading.current = true;
    setDevices([]);
  }

  useEffect(() => {
    setTimeout(() => {
      const fullScreen = getQuery('fullScreen', location)
      if (fullScreen) setItemMax(fullScreen)
    }, 100);
  }, [location.search]);

  useEffect(() => {
    if (devices) {
      setLoadingPage(false);
      if (!devFocus && (typeof driv !== 'number')) {
        const device = devices.find((d) => d.id === parseInt(localStorage.getItem('devFocus')))
        if (device) setDevFocus(device);
        else setDevFocus(devices[0]);
      }
    }
  }, [devices])

  const [updateItem, setUpdateItem] = useState();
  const heightWindow = useWindowSize().height;
  const lockLayout = useContext(UserContext).lockLayout.rides;
  const uiConfig = useContext(UserContext).uiConfig;
  const sideBar = useContext(UserContext).uiConfig.sideBar;
  const layoutRides = useContext(UserContext).uiConfig.layoutRides;
  const listClientSearch = useContext(UserContext).listClientSearch

  const { setUiConfig, callChangeUiConfig, setListClientSearch, setDev, setDriv, setNotification } = useContext(UserContext);

  useEffect(() => { callChangeUiConfig.current = 0 }, [])

  client.onmessage = (message) => {
    let data = WsAnal(message)
    // console.log('new data Rides', data);
    // update message
    if (data && data.length > 0) {
      setDevices(WSdata(data, devices, 4));
      setDev(WSdata(data, devices, 4));
      const ridesOrigin = WSdata(data, rides, 6);
      setRides(ridesOrigin);
      setRidesDaily(groupDataRides(ridesOrigin));
      const updateStats = WSdata(data, stats, 6, true);
      if (updateStats) setStats(updateStats);
      setListClientSearch(WSdataList(data, listClientSearch, 11));
      setReport(WSdataList(data, report, 13));
    }
    // first new message from query
    else {
      if (data && data.id === 4) {
        const res = WSdata(data, devices, 4).list;
        setDevices(res);
        setDev(res);
        setLoadingPage(false);
      }
      if (data && data.id === 6) {
        const ridesOrigin = WSdata(data, rides, 6);
        setStats(ridesOrigin.aux)
        setRides(ridesOrigin.list);
        setRidesDaily(groupDataRides(ridesOrigin.list));
      }
      if (data && data.oid === 7) {
        if (loadingCurRideTime.current) clearTimeout(loadingCurRideTime.current)
        setLoadingRide(false)
        const dateFromServer = WSdata(data, null, 7);
        // push new ride to data which already local
        let res = {};
        // important is is neccesery if you using ridesPoints from useState you can use copy function slice()
        if (ridesPoints && Object.keys(ridesPoints).length > 0) res = { ...ridesPoints };
        dateFromServer.list.forEach((point) => {
          const rideId = point['rideId'];
          if (!res[rideId]) { res[rideId] = {} }
          if (!res[rideId].points) { res[rideId].points = [] }
          res[rideId].points.push(point);
        })
        // console.log('res', res);
        // console.log('ridesTemp',ridesPointsTemp);
        setRidesPoints(res);
        setRidesPointsMemo({ ...res, ...ridesPointsMemo });
      }
      if (data && data.id === 11) setListClientSearch(WSdata(data, listClientSearch, 11));
      if (data && data.id === 16) {
        const res = WSdata(data, drivers, 16).list;
        setDrivers(res);
        setDriv(res);
      }
      if (data && data.id === 13) setReport(WSdata(data, report, 13))
      if (data && data.oid === 17) {
        setEditRides(WSdata(data, null, 17))
        if (data.value) {
          setRidesIdsFocus([])
          setRidesIds([])
          toast.success(t('change_saved'));
        }

      }
      if (data && data.id === 26) setNotification(WSdata(data, null, 26))
    }
    setTimeout(() => {
      if (loading) setLoading(false);
    }, 5000);
  }

  client.onclose = (e) => {
    console.log('WS connection close on rides clear data', e);
    setDevices(false);
    setDrivers(false);
    setDevFocus(false);
    setRides(false);
    setRidesDaily(false);
    setRidesIds([]);
    setRidesIdsFocus([]);
    setRidesPoints(false);
    setRidesPointsMemo(false);
    setStats(false);
    setDate(false)
  }

  useEffect(() => {
    //delete all if switch costumer
    if (!dev && !driv && prevDevFocus) {
      setDevices(dev);
      setDrivers(driv);
      setDevFocus(false);
      setRides(false);
      setRidesDaily(false);
      setRidesIds([]);
      setRidesIdsFocus([]);
      setRidesPoints(false);
      setRidesPointsMemo(false);
      setStats(false);
      setDate(false)
      console.log('costumer change delete data from rides');
    }
  }, [costumer])

  const resize = (() => {
    setTimeout(() => {
      setUpdateItem(!updateItem)
    }, 200);
  })

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setUpdateItem(!updateItem);
    }, 400);
  }, [sideBar])

  useEffect(() => {
    if (devices.length > 0) WsSend({ method: 'unregister', id: 4 });
  }, [devices])

  useEffect(() => {
    if (defaultLayout && defaultLayout[1] === '/rides') {
      setLayout(!lockLayout ? calcMaxH(layoutRidesDefault, heightWindow) : layoutRidesDefault)
    }
  }, [defaultLayout])

  useEffect(() => {
    // console.log('ridesPointsMemo', ridesPointsMemo);
    const ridesIdsNow = Object.keys(ridesPoints).map((i) => { return parseInt(i) })
    let ridesIdsNeed = ridesIds.filter(id => !ridesIdsNow.includes(id))
    let dataFromCache = false;
    // console.log('ridesIdsNow', ridesIdsNow);
    // console.log('ridesIdsNeed', ridesIdsNeed)
    if (ridesPointsMemo && Object.keys(ridesPointsMemo).length > 0) {
      // move data from cache memory
      let newRidesPoints = { ...ridesPoints };
      Object.entries(ridesPointsMemo).forEach(([rideId, val]) => {
        if (ridesIdsNeed.includes(parseInt(rideId))) {
          dataFromCache = true;
          const findIndex = ridesIdsNeed.indexOf(parseInt(rideId));
          newRidesPoints[rideId] = val;
          ridesIdsNeed.splice(findIndex, 1);
          return null
        }
        else return val
      });
      // console.log('ridesOnMap', newRidesPoints);
      setRidesPoints(newRidesPoints);

      // setRidesPointsTemp(newRidesPoints)
    }
    // console.log('ridesIdsNeed', ridesIdsNeed);
    if (ridesIdsNeed.length > 0 && checkMount('MapView', layout) && itemMax !== 'RidesView') {
      if (waitingGetRide.current) {
        clearTimeout(waitingGetRide.current)
      }
      waitingGetRide.current = setTimeout(() => {
        WsSend({ method: 'getRide', oid: 7, rid: ridesIdsNeed })
        loadingCurRideTime.current = setTimeout(() => { setLoadingRide(true) }, 2000);
      }, 1500);

    }
    else {
      if (!dataFromCache) {
        // move data to cache memory
        const ridesIdsRemov = ridesIdsNow.filter(id => !ridesIds.includes(id));
        if (Object.keys(ridesPoints).length > 0) {
          // let newRidesPointsMemo = {};
          // if (Object.keys(ridesPointsMemo).length > 0) newRidesPointsMemo = { ...ridesPointsMemo };
          let ridesPointsFilter = { ...ridesPoints };
          Object.entries(ridesPoints).forEach(([rideId, val]) => {
            if (ridesIdsRemov.includes(parseInt(rideId))) {
              // if (ridesIdsRemov.length > 1) { ridesIdsRemov.forEach((id) => { if (parseInt(rideId) === id) newRidesPointsMemo[rideId] = val }) }
              // else { newRidesPointsMemo[rideId] = val }
              const { [rideId]: _, ...updatedObj } = ridesPointsFilter;
              ridesPointsFilter = updatedObj;
            }
          });
          // console.log(newRidesPointsMemo);
          // console.log(ridesPointsFilter);
          // setRidesPointsMemo(newRidesPointsMemo);
          setRidesPoints(ridesPointsFilter)
        }

        //old version
        // if (ridesPoints) {
        //   let newRidesPointsMemo = []
        //   if (ridesPointsMemo.length > 0) newRidesPointsMemo = ridesPointsMemo.slice();
        //   let ridesPointsTemp = ridesPoints.map((ride, i) => {
        //     if (ridesIdsRemov.includes(i)) {
        //       if (ridesIdsRemov.length > 1) { ridesIdsRemov.forEach((id) => { if (i === id) newRidesPointsMemo[i] = ride }) }
        //       else { newRidesPointsMemo[i] = ride }
        //     }
        //     else return ride;
        //   });
        //   setRidesPointsMemo(newRidesPointsMemo);

        //   let ridesPointsFilter = [];
        //   ridesPointsTemp.forEach((item, index) => { if (item) ridesPointsFilter[index] = item })
        //   setRidesPoints(ridesPointsFilter)
        // }
      }
    }
    if (ridesIds.length === 0 && Object.keys(ridesPoints).length > 0) setRidesPoints({})
  }, [ridesIds])

  const removeWidget = (removeItem) => {
    const res = filterLayouts(removeItem, layout)
    setLayout(res);
    setUiConfig({ ...uiConfig, layoutRides: res });
  }


  useEffect(() => {
    if (widgetOnDrop) {
      let newLayouts = newLayots(layoutNew(widgetOnDrop), layout);
      setLayout(newLayouts)
      setUiConfig({ ...uiConfig, layoutRides: newLayouts });
      setTimeout(() => {
        setWidgetOnDrop(null)
      }, 200);
    }
  }, [widgetOnDrop])



  const setQuery = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate(`?${searchParams.toString()}`);
  };

  const clearQuery = () => {
    navigate({ search: '' });
  };

  const getQuery = (value) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(value);
  };

  useEffect(() => {
    if (itemMax) setQuery('fullScreen', itemMax)
    else clearQuery()
  }, [itemMax])

  useEffect(() => {
    if (!layout) {
      const l = layoutRides ? layoutRides : layoutRidesDefault;
      setLayout(!lockLayout ? calcMaxH(l, heightWindow) : l)
    }
    if (!lockLayout && refRender.current && layout) {
      setTimeout(() => {
        const newLayout = calcMaxH(layout, heightWindow);
        setLayout(newLayout)
      }, 100)
    } else refRender.current = true;
  }, [heightWindow])

  if (devices && devices.length > 0 && layout && !loadingPage) {
    return (
      <>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          margin={margin}
          breakpoints={breakpoints}
          cols={cols}
          // onBreakpointChange={(e) => { console.log('layout breakpoint', e); }}
          onLayoutChange={(items, layouts) => {
            setLayout(layouts);
            setUiConfig({ ...uiConfig, layoutRides: layouts });
          }}
          isDraggable={!lockLayout && !itemMax}
          isResizable={!lockLayout && !itemMax}
          rowHeight={rowHeight}
          compactType={'vertical'}
          onResizeStop={resize}
          resizeHandles={['se']}
          draggableHandle=".gridHandleOn"
          isDroppable={false}
          style={{ height: '100vh' }}
        >

          {checkMount('RidesView', layout) ? <GridItemComponent key='RidesView' id={"RidesView"} max={itemMax} heightWindow={heightWindow - 5}>
            <div>
              <HeaderGrid name={"RidesView"} title={t('rides')} height={ridesHeight} width={ridesWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {ridesHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <RidesView
                  setRidesIds={setRidesIds}
                  ridesIds={ridesIds}
                  setRidesIdsFocus={setRidesIdsFocus}
                  ridesDaily={ridesDaily}
                  devices={devices}
                  devFocus={devFocus}
                  prevDevFocus={prevDevFocus}
                  drivers={drivers}
                  drivFocus={drivFocus}
                  setDrivFocus={setDrivFocus}
                  WsSend={WsSend}
                  setDevFocus={setDevFocus}
                  height={ridesHeight - 24}
                  width={ridesWidth}
                  max={itemMax}
                  lock={lockLayout}
                  stats={stats}
                  date={date}
                  setDate={setDate}
                  editRides={editRides}
                  setEditRides={setEditRides}
                  loading={loading}
                  setLoading={setLoading}
                  rides={rides}
                />
              </div> : ''}
            </div>
          </GridItemComponent> : <div></div>}

          {checkMount('MapView', layout) ? <GridItemComponent key='MapView' id={"MapView"} max={itemMax} heightWindow={heightWindow - 20}>
            <div>
              <HeaderGrid name={"MapView"} title={t('map')} height={mapHeight} width={mapWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {mapHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <MapView
                  ridesPoints={ridesPoints}
                  ridesIdsFocus={ridesIdsFocus}
                  setRidesIdsFocus={setRidesIdsFocus}
                  height={mapHeight - 24}
                  width={mapWidth}
                  update={updateItem}
                /> </div> : ''}
            </div>
          </GridItemComponent> : <div></div>}

          {(checkMount('DeviceReport', layout) || itemMax === 'DeviceReport') ? <GridItemComponent key='DeviceReport' id={"DeviceReport"} max={itemMax} heightWindow={heightWindow - 5}>
            <div>
              <HeaderGrid name={"DeviceReport"} title={t('deviceReport')} height={deviceReportHeight} width={deviceReportWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {deviceReportHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DeviceReportView
                  report={report}
                  setReport={setReport}
                  devices={devices}
                  devFocus={devFocus}
                  WsSend={WsSend}
                  setDevFocus={setDevFocus}
                  height={deviceReportHeight - 24}
                  width={deviceReportWidth}
                  max={itemMax}
                  date={date}
                  setDate={setDate}
                  ridesView={checkMount('RidesView', layout)}
                  prevDevFocus={prevDevFocus}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div> : ''}
            </div>
          </GridItemComponent> : <div></div>}
        </ResponsiveGridLayout>
        <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-gray-100')} modal={true} show={loadingRide} />
        <ToastContainer
          position="bottom-center"
          autoClose={1500}
          pauseOnHover={false}
          theme="light"
          transition={Slide}
          closeOnClick
          hideProgressBar
        />
      </>
    )
  } else return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
}