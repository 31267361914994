import notstatic_0 from './notstatic_0.svg';
import notstatic_1_0 from './notstatic_1_0.svg';
import notstatic_1_45 from './notstatic_1_45.svg';
import notstatic_1_90 from './notstatic_1_90.svg';
import notstatic_1_135 from './notstatic_1_135.svg';
import notstatic_1_180 from './notstatic_1_180.svg';
import notstatic_1_225 from './notstatic_1_225.svg';
import notstatic_1_270 from './notstatic_1_270.svg';
import notstatic_1_315 from './notstatic_1_315.svg';
import place_point_start from './place_point_start.svg';
import place_point_stop from './place_point_stop.svg';
import acceleration from './warning_acc.svg';
import breake from './warning_break.svg';
import cornering from './warning_cor.svg';
import dirrection from './dirrection.svg';
import dirrectionf from './dirrectionf.svg';

export function iconMap(dev) {
    const b_0 = notstatic_0;
    const b_1_0 = notstatic_1_0;
    const b_1_45 = notstatic_1_45;
    const b_1_90 = notstatic_1_90;
    const b_1_135 = notstatic_1_135;
    const b_1_180 = notstatic_1_180;
    const b_1_225 = notstatic_1_225;
    const b_1_270 = notstatic_1_270;
    const b_1_315 = notstatic_1_315;

    const nameicon = 'b_' + ((dev.status & 1) == 1 ? (1 + '_' + (Math.floor(((dev.pos[7] + 22.5) % 360) / 45) * 45)) : 0);
    return eval(nameicon);
}

export {
    place_point_start,
    place_point_stop,
    notstatic_1_0,
    acceleration, 
    breake,
    cornering,
    dirrection,
    dirrectionf
}
