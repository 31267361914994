import React, { useState, useContext, useEffect, useRef, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import GridTable from '@nadavshaar/react-grid-table';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { client } from 'websocket';
import { masterUser, getJSONParse } from "../../components/tools";
import { parseISO, format, formatISO, subMonths, isToday } from 'date-fns'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner, Table, Dropdown, DropdownButton } from "react-bootstrap";
import { ToastContainer, toast, Slide } from 'react-toastify';

import { UserContext, isBrand, useWindowSize } from "../../components";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { statusstamp, statusstampSort, addressstamp } from '../../components/DevicesView/columnsDevicesView'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineSearch } from 'react-icons/ai'
import { IoMdArrowRoundBack } from "react-icons/io";
import Loading from "../../components/Loading";
import { FaFilter, FaFilterCircleXmark } from "react-icons/fa6";

const Excel = React.lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));

export default function AllCLients() {
    const login = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : JSON.parse(sessionStorage.getItem('login'))
    const navigate = useNavigate();
    document.title = 'Zákaznící'

    const [list, setList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(false);
    const [row, setRow] = useState(localStorage.getItem("allClients-row") ? JSON.parse(localStorage.getItem("allClients-row")) : null);
    const [filter, setFilter] = useState(localStorage.getItem("allClients-filter") ? JSON.parse(localStorage.getItem("allClients-filter")) : { created: '', contacted: '' });
    const refWS29 = useRef(null);
    const formData = useRef()
    const refRender = useRef(null);
    const height = useWindowSize().height;

    const { clientRoles, costumer, user } = useContext(UserContext);

    const [isAscending, seIsAscending] = useState(
        localStorage.getItem("allClients-isAscending") ? JSON.parse(localStorage.getItem("allClients-isAscending")) : { colId: null, isAsc: false }
    );

    const ColumnVisibility = ({ tableManager }) => {
        const { columnsVisibilityApi, columnsApi, searchApi } = tableManager;

        useEffect(() => {
            if (!refRender.current) {
                if (row && row.id) {
                    const indexScroll = tableManager.rowsApi.rows.findIndex(d => d.id == row.id);
                    tableManager.rowVirtualizer.scrollToOffset(indexScroll * 48); // 48 is size one rows
                }
                refRender.current = true;
            }
        }, [row])

    }

    useEffect(() => {
        if (masterUser.includes(login.uname) && clientRoles.some(i => i.type.symbol === 'data_curator')) {
            if (!list && !refWS29.current) {
                refWS29.current = true;
                WsSend({
                    method: 'getExportNode',
                    oid: 29,
                    type: 'allClients'
                });
                const handleMessage = (message) => {
                    let data = WsAnal(message)
                    if (data && data.oid === 29) {
                        setList(WSdata(data, null, 29))
                        setLoading(false)
                        client.removeEventListener('message', handleMessage);
                    }
                }
                client.addEventListener('message', handleMessage, { once: true });
            }
        }
        else navigate('/')
    }, [list])

    const getDetailsClient = (c) => {
        WsSend({
            method: 'getExportNode',
            oid: 29,
            type: 'getClientContacted',
            stampTo: c.id,
            stampFrom: c.server
        });
        setLoading(true)
        const handleMessage = (message) => {
            let data = WsAnal(message)
            if (data && data.oid === 29) {
                setOpen({ detail: data.value, client: c })
                setLoading(false)
                client.removeEventListener('message', handleMessage);
            }
        }
        client.addEventListener('message', handleMessage, { once: true });

    }

    const columns = [
        {
            id: 1,
            index: 0,
            field: 'id',
            width: 'auto',
            label: 'ID',
            visible: true,
            sortable: true,
        },
        {
            id: 2,
            index: 1,
            field: 'server',
            width: 'auto',
            label: 'server',
            visible: true,
            sortable: true,
            cellRenderer: ({ value }) => {
                return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className='rgt-text-truncate copy'>
                            {value}
                        </span>
                    </div>
                )
            },
        },
        {
            id: 3,
            index: 2,
            field: 'name',
            label: 'Zákazník',
            visible: true,
            sortable: true,
            cellRenderer: ({ value }) => {
                return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className='rgt-text-truncate copy'>
                            {value}
                        </span>
                    </div>
                )
            },
        },
        {
            id: 4,
            index: 3,
            field: 'idate',
            width: 'auto',
            label: 'Založen',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value, data }) => {
                if (value) {
                    let aStamp = parseISO(value);
                    return (
                        <div className='rgt-cell-inner rgt-cell-component' onClick={() => getDetailsClient(data)}>
                            <span className='rgt-text-truncate copy'>
                                {format(aStamp, 'dd.MM.yyyy')}
                            </span>
                        </div>
                    )
                }
            },
            sort: statusstampSort,
        },
        {
            id: 5,
            index: 4,
            field: 'blocked',
            label: 'Dlužník',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) {
                    return (
                        <div className='rgt-cell-inner rgt-cell-component'>
                            <input type="checkbox" defaultChecked={parseInt(value) >= 2 ? true : false} disabled={true} />
                        </div>
                    )
                }
            },
        },
        {
            id: 6,
            index: 5,
            field: 'count_device',
            label: 'Počet zař.',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            sort: ({ a, b, isAscending }) => {
                let aa = parseFloat(a)
                let bb = parseFloat(b)
                aa = aa ? aa : 0;
                bb = bb ? bb : 0;
                return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0)
            }
        },
        {
            id: 7,
            index: 6,
            field: 'service',
            label: 'Fakturace',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            sort: ({ a, b, isAscending }) => {
                let aa = parseFloat(a.replace(/\s+/g, ''))
                let bb = parseFloat(b.replace(/\s+/g, ''))
                aa = aa ? aa : 0;
                bb = bb ? bb : 0;
                return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0)
            }
        },
        {
            id: 8,
            index: 7,
            field: 'has_pro',
            label: 'PRO',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <input type="checkbox" defaultChecked={value === 'true' ? true : false} disabled={true} />
                    </div>
                )
            },
        },
        {
            id: 9,
            index: 8,
            field: 'has_lte',
            label: 'LTE',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <input type="checkbox" defaultChecked={value === 'true' ? true : false} disabled={true} />
                    </div>
                )
            },
        },
        {
            id: 10,
            index: 9,
            field: 'has_can',
            label: 'CAN',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <input type="checkbox" defaultChecked={value === 'true' ? true : false} disabled={true} />
                    </div>
                )
            },
        },
        {
            id: 11,
            index: 10,
            field: 'has_truck',
            label: 'Nakladní',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <input type="checkbox" defaultChecked={value === 'true' ? true : false} disabled={true} />
                    </div>
                )
            },
        },
        {
            id: 12,
            index: 11,
            field: 'has_ddd',
            label: 'DDD',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <input type="checkbox" defaultChecked={value === 'true' ? true : false} disabled={true} />
                    </div>
                )
            },
        },
        {
            id: 13,
            index: 12,
            field: 'lastcontacted',
            width: 'auto',
            label: 'Pos. kontakt',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value, data }) => {
                if (value) {
                    let aStamp = parseISO(value);
                    return (
                        <div className='rgt-cell-inner rgt-cell-component' onClick={() => getDetailsClient(data)}>
                            <span className='rgt-text-truncate copy'>
                                {format(aStamp, 'dd.MM.yyyy')}
                            </span>
                        </div>
                    )
                }
            },
            sort: statusstampSort,
        },
        {
            id: 14,
            index: 13,
            field: 'dealer',
            label: 'Obchodník',
            visible: true,
            sortable: true,
            searchable: true,
        },
        {
            id: 15,
            index: 14,
            field: 'zip',
            label: 'PSČ',
            visible: true,
            sortable: true,
            searchable: true,
        },
    ]

    const columnsDetail = [
        {
            id: 1,
            field: 'stamp',
            label: 'Datum',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) {
                    let aStamp = parseISO(value);
                    return (
                        <div className='rgt-cell-inner rgt-cell-component'>
                            <span className='rgt-text-truncate'>
                                {format(aStamp, 'dd.MM.yyyy HH:mm')}
                            </span>
                        </div>
                    )
                }
            },
            sort: statusstampSort,
        },
        {
            id: 2,
            field: 'text',
            label: ' ',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                if (value) return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className='rgt-text-truncate'>
                            {getJSONParse(value) ? getJSONParse(value) : value}
                        </span>
                    </div>
                )
            },
        },
        {
            id: 3,
            field: 'username',
            label: 'Uživatel',
            visible: true,
            sortable: true,
            searchable: false,
        },
    ]

    const getRows = ((r) => {
        if (!r) return null
        let res = r.map((d, index) => {
            let service = parseFloat(d.service);
            if (d.iperiod !== 3) {
                if (d.iperiod === 4) service = service / 3
                else if (d.iperiod === 5) service = service / 6
                else if (d.iperiod === 6) service = service / 12

            }
            return ({
                'id': d.id.toString(),
                'name': d.name.toString(),
                'idate': d.idate,
                'count_device': d.count_device,
                'service': service.toLocaleString(),
                'server': d.server,
                'has_pro': d.hardware.some(hw => hw.includes('640') || hw.includes('650')),
                'has_lte': d.hardware.some(hw => hw.includes('FMC')),
                'has_can': d.kind.some(k => k.includes('Fuelmeter')) || d.hardware.some(hw => hw.includes('150')),
                'has_truck': d.kind.some(k => k.includes('Truck') || k.includes('Lorry') || k.includes('Bus')),
                'dealer': d.dealer,
                'blocked': d.ages.filter(da => da > 0).length,
                'lastcontacted': d.lastcontacted,
                'has_ddd': d.ddd_count > 0,
                'zip': d.zip
            })
        })
        if (filter.created !== '') res = res.filter(c => parseISO(c.idate) < subMonths(new Date(), filter.created))
        if (filter.contacted !== '') res = res.filter(c => c.lastcontacted ? parseISO(c.lastcontacted) < subMonths(new Date(), filter.contacted) || isToday(parseISO(c.lastcontacted)) : true)

        return res
    })

    if (!loading) return (
        <div className="config" style={{ minHeight: '100vh' }}>
            <div className='gridHandle d-flex justify-conten-evenly'>
                <div className="back">
                    <Link to="/admin">
                        <IoMdArrowRoundBack className="fs-5 mx-2 text-secondary my-1 ms-2" />
                    </Link>
                </div>
                <span className={`ms-2 gridHandleOn`}>Zákaznící</span>
                <span >
                    <Dropdown className="ms-3">
                        <Dropdown.Toggle variant="light" className="p-0 px-2">
                            {filter.created || filter.contacted ? <FaFilterCircleXmark /> : <FaFilter />}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="me-2 bg-light">
                            <div className="px-2 pt-3">
                                <Form ref={formData} onSubmit={(e) => {
                                    e.preventDefault();
                                }}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Založeno před</InputGroup.Text>
                                        <Form.Control
                                            placeholder="počet měsíců"
                                            name="created"
                                            type="search"
                                            value={filter.created}
                                            onChange={(e) => {
                                                localStorage.setItem("allClients-filter", JSON.stringify({ ...filter, created: e.target.value }))
                                                setFilter({ ...filter, created: e.target.value });
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Poslední kontakt více než</InputGroup.Text>
                                        <Form.Control
                                            placeholder="počet měsíců"
                                            name="contacted"
                                            type="search"
                                            value={filter.contacted}
                                            onChange={(e) => {
                                                localStorage.setItem("allClients-filter", JSON.stringify({ ...filter, contacted: e.target.value }))
                                                setFilter({ ...filter, contacted: e.target.value });
                                            }}
                                        />
                                    </InputGroup>
                                </Form >
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                </span>
                <span className="m-auto">  </span>
                <Suspense >
                    <span style={{ position: 'fixed', right: '15%', paddingRight: '70px' }}>
                        <Excel
                            excelData={getRows(list)}
                            fileName={'allClients'}
                            clientName={costumer}
                            exportCsv={true}
                            classStyle={'mx-1 p-0 px-1'} />
                        <Excel
                            excelData={getRows(list)}
                            fileName={'allClients'}
                            clientName={costumer}
                            exportCsv={false}
                            classStyle={'mx-1 p-0 px-1'} />
                    </span>
                </Suspense >
            </div>
            <div className="d-flex justify-content-center">
                <GridTable
                    style={{ minHeight: 'unset', height: (height - 30) + 'px' }}
                    className={'config'}
                    columns={columns}
                    rows={getRows(list)}
                    showSearch={true}
                    showColumnVisibilityManager={true}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: ''
                    }}
                    icons={{ search: <AiOutlineSearch /> }}
                    highlightSearch={true}
                    onSortChange={({ colId, isAsc }) => {
                        seIsAscending({ colId: colId, isAsc: isAsc })
                        localStorage.setItem("allClients-isAscending", JSON.stringify({ colId: colId, isAsc: isAsc }))
                    }}
                    components={{ ColumnVisibility }}
                    sort={isAscending}
                    selectedRowsIds={[row ? row.id : '']}
                    onRowClick={({ data }) => {
                        localStorage.setItem("allClients-row", JSON.stringify({ id: data.id }))
                        setRow(data)
                    }}
                />
            </div>
            {open ? (
                <Modal size='xl' fullscreen='sm-down' centered show={open} onHide={() => { setOpen(false); setText(false) }} >
                    <Modal.Header closeButton >
                        <Modal.Title>{open.clientname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Form onSubmit={(e) => {
                                e.preventDefault();

                                WsSend({
                                    method: 'getExportNode',
                                    oid: 29,
                                    type: 'setClientContacted',
                                    device: JSON.stringify(text.replace(/\r?\n/g, '\\r')),
                                    stampTo: JSON.stringify({ client: open.client.id, server: open.client.server }),
                                    stampFrom: user.uname
                                });
                                refRender.current = false;
                                setLoading(true)
                                const handleMessage = (message) => {
                                    let data = WsAnal(message)
                                    // console.log('new data setClientContacted', data);
                                    if (data && data.oid === 29) {
                                        client.removeEventListener('message', handleMessage);
                                        if (data.value === 1) {
                                            setOpen(false);
                                            // let aDetail = open.detail;
                                            // aDetail.unshift({ stamp: formatISO(new Date), text: JSON.stringify(text.replace(/\r?\n/g, '\\r')), username: user.uname })
                                            // setOpen({ ...open, detail: aDetail })
                                            refWS29.current = false;
                                            setList(false)
                                            toast.success('Úspešně uloženo')
                                        }
                                        else navigate('/error')
                                    }
                                }
                                client.addEventListener('message', handleMessage, { once: true });
                            }}>
                                <div className="d-flex flex-column align-items-center">
                                    <Form.Control className="w-75 my-2" type="text" style={{ minHeight: '80px' }} as="textarea" onChange={(e) => {
                                        setText(e.target.value)
                                    }} />

                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <Button disabled={text ? false : true} className={false ? "w2-5" : "w-50 " + "max-w-50vh mb-3 my-2 btn btn-dark"} type="submit">{
                                        false ? <Spinner animation="border" role="status"><span className="visually-hidden">Nahrávám</span></Spinner> : 'Přidat'
                                    }</Button>
                                </div>
                            </Form>
                            <GridTable
                                // style={{ minHeight: 'unset', height: (height - 30) + 'px' }}
                                columns={columnsDetail}
                                rows={open.detail}
                                showSearch={false}
                                showColumnVisibilityManager={false}
                                showRowsInformation={false}
                                isPaginated={false}
                                texts={{
                                    search: '',
                                    noResults: ''
                                }}
                            />
                        </>
                    </Modal.Body>
                </Modal>) : ''}
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                pauseOnHover={false}
                theme="light"
                transition={Slide}
                closeOnClick
                hideProgressBar
            />
        </div>

    )
    else return (
        <>
            <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                pauseOnHover={false}
                theme="light"
                transition={Slide}
                closeOnClick
                hideProgressBar
            />
        </>
    )
}