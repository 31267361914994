import { ReactComponent as LogoLogiscare } from './logo.svg';
import { ReactComponent as LogominLogiscare } from './logomin.svg';
import { ReactComponent as Park } from './park.svg';
import { ReactComponent as Ride } from './ride.svg';
import { ReactComponent as Ign } from './ign.svg';
import { ReactComponent as Off } from './off.svg';
import { ReactComponent as On } from './on.svg';
import { ReactComponent as SmallCircel } from './smallCircel.svg'
import { ReactComponent as EditRides } from './editRides.svg'
import { ReactComponent as CarsGroup } from './carsGroup.svg'
import { ReactComponent as FilterTime } from './filterTime.svg'
import { ReactComponent as Acceleration } from './acceleration.svg'
import { ReactComponent as Breaking } from './breaking.svg'
import { ReactComponent as Cornering } from './cornering.svg'
// import { ReactComponent as Logo_alfakomtemp } from '../brand/alfakomtemp/images/logo.svg'
// import { ReactComponent as Logomin_alfakomtemp2 } from '../brand/alfakomtemp/images/logomin.svg'
import { ReactComponent as LogoGlobalsec } from '../brand/globalsec/images/logo.svg';
import { ReactComponent as LogominGlobalsec } from '../brand/globalsec/images/logomin.svg';
import { ReactComponent as LogoAutopujcovna } from './logo_CRo_Autopujcovna.svg';

let Logo = LogoLogiscare;
let Logomin = LogominLogiscare;
// if (process.env.REACT_APP_BRAND === 'alfakomtemp') {
//     Logo = Logo_alfakomtemp;
//     Logomin = Logomin_alfakomtemp2;
// } 
if (process.env.REACT_APP_BRAND === 'globalsec') {
    Logo = LogoGlobalsec;
    Logomin = LogominGlobalsec;
}

export {
    Logo,
    Logomin,
    Park,
    Off,
    On,
    Ride,
    Ign,
    SmallCircel,
    EditRides,
    CarsGroup,
    FilterTime,
    Acceleration,
    Breaking,
    Cornering,
    LogoAutopujcovna
};