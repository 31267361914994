import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Button, Container, Dropdown, DropdownButton, Form } from "react-bootstrap"
import { Link } from "react-router-dom";
import { client } from 'websocket';

import { IoIosArrowBack } from "react-icons/io";
import { FaTachographDigital } from "react-icons/fa6";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { UserContext } from "../../components";


export default function Dashboards() {
    const { t, i18n } = useTranslation('translation');

    const { clientRoles, setListClientSearch, listClientSearch, setUiConfig, uiConfig } = useContext(UserContext);

    client.onmessage = (message) => {
        let data = WsAnal(message)
        // console.log('new data', data);

        if (data) {
            // update message
            if (data.length > 0) {
                setListClientSearch(WSdataList(data, listClientSearch, 11));
            }

            // first new message from query
            else {
                if (data) {
                    if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
                }

            }
        }
    }

    const handeChange = (type) => {
        const newMenu = { ...uiConfig.menu, tacho: !uiConfig.menu.tacho }
        setUiConfig({ ...uiConfig, menu: newMenu })
    }

    return (
        <div className="bg-white d-flex align-items-center justify-content-center config" style={{ minHeight: '100vh' }}>
            <div className="w-50 d-flex flex-column align-items-center shadow mb-5 bg-body rounded">
                <div className="d-flex w-100">
                    <div className="back mt-4 pt-1 ms-4">
                        <Link to="/config">
                            <IoIosArrowBack className="fs-3 text-secondary" />
                        </Link>
                    </div>
                    <div className="d-flex flex-column align-items-center ms-4">
                        <h3 className="my-4">{t('page.dashboards')}</h3>
                    </div>
                </div>
                {clientRoles.some(i => i.type.symbol === 'view_history') || clientRoles.some(i => i.type.symbol === 'edit_maintenances') ?
                    <Button variant="light" className="d-flex align-items-center w-100 px-4 py-2" onClick={() => { handeChange('tacho') }}>
                        <div className="icon"><FaTachographDigital className="mx-2 fs-3 text-primary" /></div>
                        <div className="ms-4 fs-5 text">{t('widget.tacho')}</div>
                        <div className="m-auto"></div>
                        <Form.Check
                            className="me-4 cursorpointer"
                            type="switch"
                            checked={uiConfig.menu.tacho}
                            onChange={() => { }}
                        />
                    </Button> : <></>}
            </div>
        </div>
    )
}