import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { prevMonth } from "../DatePickerComponent/DateSelect"
import GridTable from '@nadavshaar/react-grid-table';
import { AiOutlineSearch } from 'react-icons/ai'

import { WsSend } from "../../scripts/ws";

import Loading from "../Loading";

export function getColumnsCostumerReport(data) {
    return Object.keys(data.list[0]).map((key, index) => ({
        id: index,
        field: key,
        label: data.header[index],
        width: 'auto'
    }));
}


export function CostumerReport({ costumer, data, date, setDate, height }) {
    const { t } = useTranslation('translation');

    const [columns, setColumns] = useState(false);

    const refWS28 = useRef(null);
    const firstRender = useRef(true);


    useEffect(() => {
        if (!date) setDate(prevMonth())
        else {
            if (firstRender.current) firstRender.current = false;
            else {
                WsSend({
                    method: 'getExportNode',
                    oid: 28,
                    type: costumer.type,
                    device: costumer.device,
                    stampFrom: date[0].toDate(),
                    stampTo: date[1].toDate(),
                });
            }
        }
    }, [date])

    useEffect(() => {
        if (data && data.list.length > 0) {
            setColumns(getColumnsCostumerReport(data));
        }
    }, [data])

    if (!data && date && !refWS28.current) {

        refWS28.current = true;
        WsSend({
            method: 'getExportNode',
            oid: 28,
            type: costumer.type,
            device: costumer.device,
            stampFrom: date[0].toDate(),
            stampTo: date[1].toDate()
        });
    }
    if (columns) return (
        <GridTable
            style={{ minHeight: 'unset', height: (height - 100) + 'px' }}
            columns={columns}
            rows={data.list}
            showSearch={true}
            showColumnVisibilityManager={false}
            showRowsInformation={true}
            isPaginated={false}
            texts={{
                search: '',
                noResults: t('devices.notFind'),
                totalRows: t('tablePaginated.totalRows'),
            }}
            icons={{ search: <AiOutlineSearch /> }}
            className="stats"
            enableColumnsReorder={false}
        />
    )
    else if (data === 0) return <div className="ms-3 fs-5 text-danger">Při generovní exportu se vyskytla chyba!</div>
    else return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} height={height - 150} />

}