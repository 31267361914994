import React, { useState, useContext, useEffect } from "react";
import { Form, Button, FloatingLabel, InputGroup, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import { client } from "websocket";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { CZ, US, GR, SK, PL } from 'country-flag-icons/react/3x2'
import * as Sentry from "@sentry/react";

import { WsAnal, WsLogin, WsLogout, WsNew, WsSend } from "../scripts/ws";
import { UserContext, getQuery, clearQuery, isBrand } from '../components';
import { Logo, LogoAutopujcovna } from "../images"
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { uiConfigDefault, mergeObjects } from "../components/uiConfig";
import RegistrationCarRental from "./CarRental/RegistrationCarRental";

export default function Login({ setLogin }) {
    const loginsave = (localStorage.getItem('login') ? localStorage.getItem('login') : sessionStorage.getItem('login'));
    const navigate = useNavigate();
    const location = useLocation();
    const { setUiConfig } = useContext(UserContext);
    const { t, i18n } = useTranslation();

    const [loginfalse, setLoginfalse] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [checkbox, setCheckbox] = useState();
    const [showpass, setShowpass] = useState(false);
    const [startlogin, setStartlogin] = useState(false);
    const [carRentalRegistraion, setCarRentalRegistraion] = useState(false);
    const { setUser, setCostumer, setClientGroups, setClientRoles, setClientFilters, setClientLayers } = useContext(UserContext);
    let domains = ['ws01', 'ws02', 'ws99'];
    if (process.env.REACT_APP_BRAND === 'alfakomtemp') domains = ['ws02', 'ws07'];
    else if (process.env.REACT_APP_BRAND === 'globalsec') domains = ['ws04', 'ws08'];
    const { dev, setDev, setDriv } = useContext(UserContext);

    const unQuery = getQuery('un', location);
    const pwQuery = getQuery('pw', location);

    const getLang = () => {
        if (i18n.language.substring(0, 2) === 'cs') return (<Dropdown.Item eventKey="1"><CZ style={{ height: '20px' }} className="me-2 " />{t('login.lang_cs')}</Dropdown.Item>)
        else if (i18n.language.substring(0, 2) === 'en') return (<Dropdown.Item eventKey="2"><US style={{ height: '20px' }} className="me-2 " />{t('login.lang_en')}</Dropdown.Item>)
        else if (i18n.language.substring(0, 2) === 'el') return (<Dropdown.Item eventKey="3"><GR style={{ height: '20px' }} className="me-2 " />{t('login.lang_el')}</Dropdown.Item>)
        else if (i18n.language.substring(0, 2) === 'sk') return (<Dropdown.Item eventKey="4"><SK style={{ height: '20px' }} className="me-2 " />{t('login.lang_sk')}</Dropdown.Item>)
        else if (i18n.language.substring(0, 2) === 'pl') return (<Dropdown.Item eventKey="5"><PL style={{ height: '20px' }} className="me-2 " />{t('login.lang_pl')}</Dropdown.Item>)
    }

    if (loginsave && !unQuery && !pwQuery) {
        if (!client) WsNew(JSON.parse(loginsave).domain);
        client.onopen = (msg) => {
            console.log('WebSocket connected', msg);
            WsLogin(JSON.parse(loginsave).uname, JSON.parse(loginsave).pass);
        };
        client.onmessage = (message) => {
            let data = WsAnal(message)
            console.log('login_data', data);
            if (data) {
                if (data.value && data.oid === 2) {
                    setUser({ name: data.value.name.length > 0 ? data.value.name : JSON.parse(loginsave).uname, uname: JSON.parse(loginsave).uname, id: data.value.id, dzoom: data.value.dzoom, currency: data.value.currency });
                    setClientGroups(data.value.groups);
                    setClientRoles(data.value.roles);
                    setClientLayers(data.value.layers);
                    if (data.value.filtersUser) setClientFilters(data.value.filtersUser)
                    if (localStorage.getItem('clientId')) {
                        WsSend({ method: 'setParam', name: 'client', value: localStorage.getItem('clientId') });
                        setCostumer(localStorage.getItem('clientName'));
                    } else setCostumer(data.value.company + ' [' + data.value.client + '] ' + data.value.cname);
                    const uiConfig = mergeObjects(data.value.uiConfig, uiConfigDefault());
                    setUiConfig(uiConfig);
                    sessionStorage.setItem('uiConfig', JSON.stringify(uiConfig));
                    if (data.value.lang) i18n.changeLanguage(data.value.lang);
                    if (dev) setDev(false);
                    if (data.value.ddriver) setDriv(data.value.ddriver)
                    Sentry.setUser({
                        clienId: data.value.client,
                        clientName: data.value.cname,
                        username: JSON.parse(loginsave).uname,
                        ws: JSON.parse(loginsave).domain
                    });
                    setLogin(true);
                }
                if (!data.value && data.oid === 2) {
                    WsLogout();
                    setStartlogin(false);
                    setLoginfalse(true);
                }
            }
        }
        client.onclose = (close) => {
            console.log('WS connection close', close);
        }
        client.onerror = (msg) => {
            console.log('WS connection error', msg);
            Sentry.captureException(msg)
            navigate('/error')
        }

    }

    useEffect(() => {
        if (unQuery && pwQuery) {
            setStartlogin(true);
            tryLogin(domains, unQuery, pwQuery);
        }
    }, [])

    const loginBtn = (event) => {
        //Prevent page reload submit btn
        event.preventDefault();
        setStartlogin(true);
        tryLogin(domains, username, password);
    }

    const loginDemo = () => {
        if(process.env.NODE_ENV === 'development'){
            setStartlogin(true);
            tryLogin(['ws99'], 'go.1', '123456');
        }
        else if (isBrand('alfakomtemp')) {
            setStartlogin(true);
            tryLogin(['ws07'], 'kalimero', 'kalimero');
        }
        else {
            setStartlogin(true);
            tryLogin(['ws01'], 'demo1', 'demo');
        }

    }

    const tryLogin = async (dm, u, p) => {
        let dn = dm.length;
        let dw, un;
        let ic = u.indexOf('#');

        if (ic >= 0) {
            un = u.substring(ic + 1);
            setUsername(un)
            dw = u.substring(0, ic);
        }
        else un = u;
        if (!dw) {
            const ro = { method: 'POST', body: JSON.stringify({ username: un, password: p }), };
            for (let i = 0; i < dm.length; i++) {
                const url = "https://" + dm[i] + ".logiscare.com/jss/login";
                try {
                    const response = await fetch(url, ro);
                    const data = await response.json();
                    if (data.session) dw = dm[i];
                    dn = dn - 1;
                } catch (err) {
                    console.log(err, dm[i]);
                    dn = dn - 1;
                }
            }
        }
        if (dn === 0 && !dw) {
            setStartlogin(false);
            setLoginfalse(true);
        }
        if (dw) {
            WsNew(dw);
            client.onopen = (msg) => {
                console.log('WebSocket connected', msg);
                WsLogin(un, p);
            };
            client.onmessage = (message) => {
                let data = WsAnal(message)
                // console.log('login_data', data);
                if (data) {
                    if (data.value && data.oid === 2) {
                        setUser({ name: data.value.name.length > 0 ? data.value.name : un, uname: un, id: data.value.id, dzoom: data.value.dzoom, currency: data.value.currency });
                        setCostumer(data.value.company + ' [' + data.value.client + '] ' + data.value.cname);
                        setClientGroups(data.value.groups);
                        setClientRoles(data.value.roles);
                        setClientLayers(data.value.layers);
                        if (data.value.filtersUser) setClientFilters(data.value.filtersUser)
                        if (!loginsave) {
                            const md5 = require('md5');
                            if (checkbox) localStorage.setItem('login', JSON.stringify({ domain: dw, uname: un, pass: md5(p).toUpperCase() }));
                            else sessionStorage.setItem('login', JSON.stringify({ domain: dw, uname: un, pass: md5(p).toUpperCase() }));
                        }
                        const uiConfig = mergeObjects(data.value.uiConfig, uiConfigDefault());
                        setUiConfig(uiConfig);
                        sessionStorage.setItem('uiConfig', JSON.stringify(uiConfig));
                        if (data.value.lang) i18n.changeLanguage(data.value.lang);
                        if (dev) setDev(false);
                        if (data.value.ddriver) setDriv(data.value.ddriver)
                        Sentry.setUser({
                            clienId: data.value.client,
                            clientName: data.value.cname,
                            username: un,
                            ws: dw
                        });
                        setLogin(true);
                    }
                    if (!data.value && data.oid === 2) {
                        setStartlogin(false);
                        setLoginfalse(true);
                    }
                }
            }
        };
    }
    const handleshowpass = () => { showpass ? setShowpass(false) : setShowpass(true) }
    if (!loginsave) {
        localStorage.clear()
        return (
            <div className="bg-light vh-100 d-flex align-items-center justify-content-center login">
                <Form className="w-75 d-flex flex-column align-items-center shadow p-3 mb-5 bg-body rounded"
                    onSubmit={loginBtn}>
                    <Logo className="max-w-50vh m-4 mt-4 logo" />
                    {window.location.pathname === '/autopujcovna' ? <LogoAutopujcovna className="max-w-50vh m-4 mt-4 logo" /> : ''}
                    <FloatingLabel className="w-75 max-w-50vh my-3" controlId="floatingInput" label={t('login.user_email')}>
                        <Form.Control type="username" placeholder="username" onChange={(e) => setUsername(e.target.value)} required />
                    </FloatingLabel>
                    <InputGroup className="w-75 max-w-50vh my-3" >
                        <FloatingLabel controlId="floatingPassword" label={t('login.password')}>
                            <Form.Control style={{ borderRadius: "0.375em", paddingRight: "2.5em" }} type={(showpass) ? "text" : "password"} placeholder="password" onChange={(e) => setPassword(e.target.value)} required />
                        </FloatingLabel>
                        <i className="position-absolute btn-eye pe-auto" role="button" onClick={handleshowpass}>{showpass ? <BsEyeSlash title={t('login.hidepass')} /> : <BsEye title={t('login.showpass')} />}</i>
                    </InputGroup>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check role="button" type="checkbox" label={t('login.rememberme')} onChange={(e) => setCheckbox(e.target.checked)} />
                    </Form.Group>
                    {loginfalse ? <Form.Text className="text-danger fw-bold mb-3 fs-7"><Trans i18nKey="login.error" /></Form.Text> : ''}
                    <Button className={(startlogin ? "w2-5 " : "w-75 ") + "max-w-50vh mb-3 btn btn-dark"} type="submit">{
                        startlogin ? <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> : t('login.btn')
                    }</Button>
                    {window.location.pathname === '/autopujcovna' ? (<>
                        <Button variant="secondary" onClick={() => setCarRentalRegistraion(true)} className={"w-75 max-w-50vh mb-3"}>Nová registrace</Button>
                    </>) : ''}
                    <Form.Text id="" muted role="button" onClick={loginDemo}>
                        <Trans i18nKey="login.demo" />
                    </Form.Text>
                    <DropdownButton className="mt-3" title={getLang()} drop="up-centered" variant="light">
                        <Dropdown.Item eventKey="1" onClick={() => { i18n.changeLanguage('cs') }}><CZ style={{ height: '20px' }} className="me-2 " />{t('login.lang_cs')}</Dropdown.Item>
                        <Dropdown.Item eventKey="4" onClick={() => { i18n.changeLanguage('sk') }}><SK style={{ height: '20px' }} className="me-2 " />{t('login.lang_sk')}</Dropdown.Item>
                        <Dropdown.Item eventKey="5" onClick={() => { i18n.changeLanguage('pl') }}><PL style={{ height: '20px' }} className="me-2 " />{t('login.lang_pl')}</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => { i18n.changeLanguage('en') }}><US style={{ height: '20px' }} className="me-2 " />{t('login.lang_en')}</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => { i18n.changeLanguage('el') }}><GR style={{ height: '20px' }} className="me-2 " />{t('login.lang_el')}</Dropdown.Item>
                    </DropdownButton>
                </Form>
                <RegistrationCarRental show={carRentalRegistraion} set={setCarRentalRegistraion} />
            </div>
        )
    }
}
