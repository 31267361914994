import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
// import Feature from "ol/Feature";
// import Zoom from 'ol-ext/featureanimation/Zoom'
// import { easeOut, linear } from 'ol/easing';
import { getVectorContext } from 'ol/render.js';
import { unByKey } from 'ol/Observable.js';
import { pulseStyle } from "./mapStyle";
import { iconKind } from "../../images/kind"
import { parseISO, format, isSameYear, isToday, formatDistance, formatDistanceStrict, getTime, intervalToDuration } from 'date-fns'

import { FaCarBattery, FaKey, FaMapMarkedAlt, FaCopy, FaTachometerAlt, FaRoute, FaThermometer, FaBatteryHalf, FaParking } from 'react-icons/fa'
import { BsSpeedometer } from 'react-icons/bs'
import { BiSolidTimer } from 'react-icons/bi'
import { GrFormClose } from 'react-icons/gr'
import { IoMdPerson } from "react-icons/io";
import { Park, Ign, Ride, Acceleration, Breaking, Cornering } from "../../images"
import i18n from '../../dictionary/i18n';
import { formatDurationOver24 } from '../RidesView/ridesRows'

export function miliToLonLat(a, b) {
  return new Point(fromLonLat([a / 3600000.0, b / 3600000.0]))
}

export function nativeBounds(dev) {
  var b = bounds(dev)
  const p1 = miliToLonLat(b[0], b[1]);
  const p2 = miliToLonLat(b[2], b[3]);
  const res = [];
  res.push(p1.getCoordinates()[0]);
  res.push(p1.getCoordinates()[1]);
  res.push(p2.getCoordinates()[0]);
  res.push(p2.getCoordinates()[1]);
  return res;
}

export function bounds(dev) {
  const p = dev;
  if (p.length === 0) return [-50 * 3600000, 15 * 3600000, 90 * 3600000, 60 * 3600000];
  const res = [180 * 3600000, 90 * 3600000, -180 * 3600000, -90 * 3600000];
  for (var i = 0; i < p.length; i++) {
    if (p[i].pos[1] < res[0]) res[0] = p[i].pos[1];
    if (p[i].pos[0] < res[1]) res[1] = p[i].pos[0];
    if (p[i].pos[1] > res[2]) res[2] = p[i].pos[1];
    if (p[i].pos[0] > res[3]) res[3] = p[i].pos[0];
  }
  if (res[2] - res[0] < 40000) {
    res[0] = res[0] - 20000;
    res[2] = res[2] + 20000;
  }
  if (res[3] - res[1] < 40000) {
    res[1] = res[1] - 20000;
    res[3] = res[3] + 20000;
  }
  return res
}


export function bounds2(dev) {
  const p = dev;
  if (p.length === 0) return [-50 * 3600000, 15 * 3600000, 90 * 3600000, 60 * 3600000];
  const res = [180 * 3600000, 90 * 3600000, -180 * 3600000, -90 * 3600000];
  for (var i = 0; i < p.length; i++) {
    if (p[i].lon < res[0]) res[0] = p[i].lon;
    if (p[i].lat < res[1]) res[1] = p[i].lat;
    if (p[i].lon > res[2]) res[2] = p[i].lon;
    if (p[i].lat > res[3]) res[3] = p[i].lat;
  }
  if (res[2] - res[0] < 40000) {
    res[0] = res[0] - 20000;
    res[2] = res[2] + 20000;
  }
  if (res[3] - res[1] < 40000) {
    res[1] = res[1] - 20000;
    res[3] = res[3] + 20000;
  }
  return res
}



export function nativeBounds2(dev) {
  var b = bounds2(dev)
  const p1 = miliToLonLat(b[0], b[1]);
  const p2 = miliToLonLat(b[2], b[3]);
  const res = [];
  res.push(p1.getCoordinates()[0]);
  res.push(p1.getCoordinates()[1]);
  res.push(p2.getCoordinates()[0]);
  res.push(p2.getCoordinates()[1]);
  return res;
}

export function centerall(dev) {
  const sm = nativeBounds(dev);
  return [sm[0] + ((sm[2] - sm[0]) / 2), sm[1] + ((sm[3] - sm[1]) / 2)]
}

export function centerall2(dev) {
  const sm = nativeBounds2(dev);
  return [sm[0] + ((sm[2] - sm[0]) / 2), sm[1] + ((sm[3] - sm[1]) / 2)]
}

export function center(sm) {
  return miliToLonLat(sm.getProperties().device.pos[1], sm.getProperties().device.pos[0])
}

export function setZInd(obj, anIndex) {
  if (!obj) return;
  // console.log(obj);
  if (obj.getProperties().index) {
    obj.getProperties().index = anIndex;
    // console.log('index');
    let stl = obj.getStyle();
    // console.log(stl);
    stl[0].setZIndex(anIndex);
    stl[1].setZIndex(anIndex);
  }
}

export function isOnScreen(obj, markSour, mapView) {
  let inc = false;
  let ex = mapView.getView().calculateExtent();
  markSour.forEachFeatureInExtent(ex, function (feature) {
    if (feature == obj) inc = true;
  })
  return inc;
}

export function markerAt(anId, markSour) {
  let res = null;
  markSour.forEachFeature(function (feature) {
    if (feature.getProperties().device && feature.getProperties().device.id == anId) res = feature;
  });
  return res;
}

export function allObjectOnScreen(map, devices) {
  const z = map.getView().getZoomForResolution(map.getView().getResolutionForExtent(nativeBounds(devices)));
  if (map.getView().getZoom() !== 19 && z !== 19 && map.getView().getZoom() !== z) {
    map.getView().setZoom(z - 0.3);
    map.getView().setCenter(centerall(devices));
  }
}

export function allRidesOnScreen(map, rides) {
  const z = map.getView().getZoomForResolution(map.getView().getResolutionForExtent(nativeBounds2(rides)));
  if (map.getView().getZoom() !== 19 && z !== 19 && map.getView().getZoom() !== z) {
    map.getView().setZoom(z - 0.3);
    map.getView().setCenter(centerall2(rides));
  }
}

// export function animatePulse(coord, map) {
//   const feature = new Feature(new Point(coord));
//   feature.setStyle(pulseStyle());

//   const animation = new Zoom({
//     duration: 1500,
//     easing: easeOut,
//     fade: easeOut
//   });

//   map.animateFeature(feature, animation)
// };

export function flashPulse(feature, layer, map) {
  const duration = 1500;

  const start = Date.now();
  const flashGeom = feature.getGeometry().clone();
  const listenerKey = layer.on('postrender', animate);

  function animate(event) {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);

    vectorContext.setStyle(pulseStyle(elapsed, duration));
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    map.render();
  }
}

export function PopupDevice({ d, overlay, feature, popup, set }) {
  const gps = (d.pos[0] / 3600000.0).toFixed(6) + ',' + (d.pos[1] / 3600000.0).toFixed(6);
  const aStamp = parseISO(d.ridestamp);
  let popupRef = popup;
  const timeDur = d.currentridestamp ? intervalToDuration({ start: parseISO(d.currentridestamp), end: new Date() }) : null;
  let parkStamp = '';
  if (d.ridestamp) {
    parkStamp = formatDistanceStrict(parseISO(d.ridestamp), new Date());
    if (parkStamp === 'hodina') parkStamp = 'hodinu';
    if (parkStamp === 'minuta') parkStamp = 'minutu';
  }
  return (
    <>
      <div onClick={() => { overlay.setPosition(undefined); popupRef = false; }} style={{ position: 'absolute', right: '0.25rem', top: '0.1rem' }}><GrFormClose className="cursorpointer text-secondary click" /></div>
      <div onClick={() => { overlay.setPosition(feature.getGeometry().getCoordinates()); popupRef = true; set({ type: 'device' }) }} className="cursordefault">
        {/* <div className="cursordefault"> */}
        <div className="d-flex align-items-center mt-1">
          {/*{d.status & 1 ? <Ign /> : <Park />}*/}
          <div style={{ width: '100px' }}>{iconKind(d, true)}</div>
          <div>
            <div>{d.name}</div>
            {d.plate !== '-' & d.plate !== ' ' ? (<div className="device-plate cursorpointer" onClick={() => { navigator.clipboard.writeText(d.plate) }}>{d.plate}</div>) : ""}
          </div>
        </div>
        {d.status & 1 ? (<span>
          {!d.isstatic ? (<div className="fs-7 mt-1">
            <div>
              <span className="fw-bold text-primary me-2">{i18n.t('devices.nowRide') + ":"}</span>
              {d.currentridedist ? (<span>
                <FaRoute className="text-secondary ms-2 mb-1 fs-6" />
                <span className="mx-1">{i18n.t('stats.distance')}</span>
                <span className="fw-bold text-primary">{Math.round(d.currentridedist / 1000).toLocaleString() + ' km'}</span></span>) : ""}
            </div>
            {timeDur ? (<span>
              <BiSolidTimer className="text-secondary me-1 mb-1 fs-5" />
              {i18n.t('stats.time')}
              <span className="fw-bold text-primary ms-1" title="hh:mm">{(timeDur.hours).toString().padStart(2, '0') + ":" + (timeDur.minutes).toString().padStart(2, '0')}</span></span>) : ""}
            <FaTachometerAlt className="text-secondary me-1 mb-1 fs-6 ms-3" />
            {i18n.t('devices.speed')}
            <span className="fw-bold text-primary ms-1">{d.pos[3] + ' km/h'}</span></div>) : <span>{i18n.t('devices.on')}</span>}
        </span>) : (<div className="fs-7 text-secondary mt-1">
          {!d.isstatic ? <FaParking className="fs-5 mb-1" /> : ""} <span className="fw-bold text-primary">{!d.isstatic ? i18n.t('devices.park') : i18n.t('devices.off')} {parkStamp}</span>
          {d.ridestamp ? (<span className="ms-2">{"("}{isToday(parseISO(d.ridestamp)) ? format(parseISO(d.ridestamp), 'HH:mm') : (format(parseISO(d.ridestamp), 'dd.MM.yyyy HH:mm'))}{")"}</span>) : ""}
        </div>)}
        <div className="fs-7">
          {<FaMapMarkedAlt onClick={() => { window.open(`https://www.google.com/maps?q=${gps}`, '_blank') }} className="text-secondary cursorpointer mb-1 me-1 fs-6 click" title={i18n.t('maps.openGoogle')} />}
          {d.address}
          {<FaCopy onClick={() => { navigator.clipboard.writeText(gps) }} className="text-secondary cursorpointer click ms-2 mb-1" title={i18n.t('maps.copyGPS')} />}
        </div>
        {d.drivername ? (<div className="fs-7">
          <IoMdPerson className="text-secondary me-1 mb-1 fs-6" />
          {i18n.t('devices.drivername') + ": "}
          <span className="fw-bold">{d.drivername}</span>
        </div>) : ''}

      </div>
    </>
  )
}

export function GroupDevice({ group, feature, overlay, set, popup, setDevFocus }) {
  let popupRef = popup;
  return (
    group.map((f) => {
      const d = f.values_.device
      return (
        <div className="d-flex flex-row" key={d.id} onClick={() => { overlay.setPosition(feature.getGeometry().getCoordinates()); popupRef = true; set({ type: 'group', data: group, feature: feature }); setDevFocus(d); }} >
          <div style={{ width: '60px', display: 'block', marginLeft: '-10px' }}>{iconKind(d, true)}</div>
          <div style={{ cursor: 'pointer' }}>{d.name} - {d.plate}</div>
        </div>)
    })
  )
}

export function TitleLine({ dataLine }) {
  const stamp = parseISO(dataLine.stamp)

  const getIconGreen = (val) => {
    switch (val) {
      case 1: return <Acceleration className="text-secondary fs-4 mb-1 me-1" />
      case 2: return <Breaking className="text-secondary fs-5 mb-1 me-1" />
      case 3: return <Cornering className="text-secondary fs-5 mb-1 me-1" />
    }
  }

  return (
    <div className="" style={{ cursor: 'default' }}>
      <div className="fs-7">
        {isToday(stamp) ? i18n.t('date_picker.today') + ' ' + format(stamp, 'HH:mm:ss') : (format(stamp, 'dd.MM.yyyy HH:mm:ss'))}
      </div>
      {dataLine.greent > 0 ? (<div className="fs-7">
        {getIconGreen(dataLine.greent)}
        {i18n.t('maps.greent_' + dataLine.greent)} <b>{(dataLine.greenv / 10).toLocaleString()} m/s<sup>2</sup></b>
      </div>) : <></>}
      <div className="fs-7">
        <FaTachometerAlt className="text-secondary me-1 mb-1 fs-6" />
        {i18n.t('devices.speed') + ": "}<span className="fw-bold">{dataLine.speed + ' km/h'}</span>
      </div>
    </div>
  )
}