import React, { useState, useEffect, useRef } from "react";
import { client } from 'websocket';

import { WsSend, WsAnal } from "../scripts/ws";



export default function Rides() {

  const [time, setTime] = useState();
  const [timestop, stopTime] = useState(false);


  if (!time && !timestop) WsSend({ method: 'getTimestamp', oid: 1 });


  client.onmessage = (message) => {
    let data = WsAnal(message)
    if (data.length >= 1) {
      if (data[0].value && data[0].id === 1) {
        let t = new Date(data[0].value);
        setTime(t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds())
      }
      if (data[0].newValue && data[0].object === 1) {
        let t = new Date(data[0].newValue);
        setTime(t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds())
      }
    }
  }

  client.onclose = (e) => {
    console.log('WS connection close', e);
    setTime(false)
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <div className="mb-3">{time}</div>
        <button className="mb-5" onClick={() => {
          WsSend({
            method: "setRides",
            oid: 17,
            id: [166726502, 166724177, 166723386, 166723930, 166724351, 166724927, 166730501],
            purpose: "NOC POB"
          })
        }}>Poslat setRides s chybou</button>
        <button onClick={() => {
          WsSend({
            method: "setRides",
            oid: 17,
            id: [166726502, 166724177, 166723386, 166723930, 166724351, 166724927, 166730501],
            purpose: "NOC POB",
            empty: ''
          })
        }}>Poslat setRides s přidaným parametrem</button>
      </div>
    </>
  )

};

