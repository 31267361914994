import { differenceInDays, parseISO, format, formatDistanceStrict, isToday, subDays  } from 'date-fns'

export function dddViewVehicleRows(filesInfo, devices, files) {
  let res = []
  // devices.forEach((d) => {
  //   if (d.cfg.indexOf('ddd') >= 0) {
  //     let dddVehicle = []
  //     let dddDrivers = []
  //     files.forEach((file) => {
  //       if (file.device === d.id) {
  //         if (!file.driver) {
  //           if (file.name != 'M_xxx.ddd') dddVehicle.push(file)
  //         }
  //         else dddDrivers.push(file)
  //       }
  //     })
  //     if (dddVehicle.length > 1) {
  //       dddVehicle.sort((a, b) => new Date(b.stamp) - new Date(a.stamp));
  //     }
  //     res.push({
  //       'id': d.id,
  //       'name': d.name,
  //       'plate': d.plate,
  //       'lastDownload': dddVehicle.length > 0 ? (dddVehicle[0].stamp + ';' + differenceInDays(new Date(), parseISO(dddVehicle[0].stamp))) : '',
  //       'countFiles': dddVehicle.length > 0 ? (dddVehicle.length) : '',
  //       'files': dddVehicle,
  //       'filesDrivers': dddDrivers,
  //       'countFilesDrivers': dddDrivers.length > 0 ? (dddDrivers.length) : '',
  //       'lastRide': formatDistanceStrict(parseISO(d.ridestamp), new Date(), { addSuffix: true, unit: 'day' })
  //     })
  //   }
  // })
  
  const devWithDDD = devices.filter(d => d.cfg && d.cfg.indexOf('ddd') >= 0)
  filesInfo.forEach((f) => {
    if (f.type === 'device') {
      const suspend = !devWithDDD.some(d => d.id === f.id);
      res.push({
        'id': f.id,
        'name': f.name,
        'plate': f.plate,
        'lastDownload': f.vehicle_count > 0 && !suspend ? f.stamp : null,
        'countFiles': f.vehicle_count > 0 ? (f.vehicle_count) : '',
        'countFilesDrivers': f.driver_count > 0 ? (f.driver_count) : '',
        'lastRide': f.ridestamp && !suspend ? f.ridestamp : null,
        'lastIdFile': f.id_last_file,
        'suspend': suspend,
        'type': 'DDDViewVehicle'
      })
    }
  })

  const withoutFile = devWithDDD.filter(d2 => !res.some(d1 => d2.id === d1.id));
  if (withoutFile.length > 0) withoutFile.forEach(d => {
    res.push({
      'id': d.id,
      'name': d.name,
      'plate': d.plate,
      'lastDownload': null,
      'countFiles': null,
      'countFilesDrivers': null,
      'lastRide': d.ridestamp ? d.ridestamp : null,
      'lastIdFile': null
    })
  })
  function suspendToLast(a, b) {
    if (a.suspend && !b.suspend) return 1;
    if (!a.suspend && b.suspend) return -1;
    return 0;
  }
  res.sort(suspendToLast)
  return res

}

export function dddViewDriversRows(filesInfo, drivers) {
  let res = []
  // drivers.forEach((d) => {
  //   let dddDriver = []
  //   files.forEach((file) => {
  //     const type = file.name.split('_')[0]
  //     if (file.driver === d.id && type === 'C') dddDriver.push(file)
  //   })
  //   if (dddDriver.length > 1) {
  //     dddDriver.sort((a, b) => new Date(b.stamp) - new Date(a.stamp));
  //   }
  //   res.push({
  //     'id': d.id,
  //     'did': d.did,
  //     'driver': true,
  //     'name': d.name === d.did && dddDriver.length > 0 ? (dddDriver[0].name.split('_')[3] + '.' + dddDriver[0].name.split('_')[4]) : d.name,
  //     'lastDownload': dddDriver.length > 0 ? (dddDriver[0].stamp + ';' + differenceInDays(new Date(), parseISO(dddDriver[0].stamp))) : '',
  //     'countFiles': dddDriver.length > 0 ? (dddDriver.length) : '',
  //     'files': dddDriver
  //   })
  // })
  filesInfo.forEach((f) => {
    if (f.type === 'driver') {
      const suspend = !drivers.some(d => d.id === f.id);
      res.push({
        'id': f.id,
        'did': null,
        'driver': true,
        'name': f.name,
        'lastDownload': f.driver_count > 0 && !suspend ? (f.stamp + ';' + differenceInDays(new Date(), parseISO(f.stamp))) : null,
        'lastRide': f.ridestamp && !suspend ? f.ridestamp : null,
        'countFiles': f.driver_count > 0 ? (f.driver_count) : '',
        'lastIdFile': f.id_last_file,
        'suspend': suspend,
        'type': 'DDDViewDrivers'
      })
    }
  })
  function suspendToLast(a, b) {
    if (a.suspend && !b.suspend) return 1;
    if (!a.suspend && b.suspend) return -1;
    return 0;
  }
  res.sort(suspendToLast)
  return res

  // const driverFiles = files.filter(item => { if (item.driver) return item })

  // const groupData = driverFiles.reduce((acc, curr) => {
  //   const driver = curr.driver;

  //   if (!acc[driver]) {
  //     // console.log(curr);
  //     const nameArr = curr.name.split('_');
  //     let nameFromFile = nameArr[3] + '. ' + nameArr[4];
  //     acc[driver] = {
  //       id: curr.id,
  //       name: nameFromFile,
  //       lastDownload: (curr.stamp + ';' + differenceInDays(new Date(), parseISO(curr.stamp))),
  //       driver: driver,
  //       files: [],
  //       countFiles: 0
  //     };
  //   }
  //   acc[driver].files.push(curr);
  //   acc[driver].countFiles++;
  //   return acc;
  // }, {});
  // const res = Object.values(groupData);
  // // console.log(res);
  // return res
}

function isStampInRange(stamp, from, to) {
  return stamp >= from && stamp <= to;
}

// export function dddListFilesRows(files, stamp, filterDevice, filterDrivers) {
//   let res = []
//   files.forEach((f) => {
//     const stampDownload = parseISO(f.stamp)
//     if (isStampInRange(stampDownload, stamp[0], stamp[1])) {
//       const data = {
//         'id': f.id,
//         'kind': f.driver ? 'driver' : 'vehicle',
//         'vehicle': f.dname,
//         'driver': f.namedriver,
//         'activityPeriod': f.stamp1 && f.stamp2 ? `${format(parseISO(f.stamp1), 'dd.MM.yyyy')} - ${format(parseISO(f.stamp2), 'dd.MM.yyyy')}` : '',
//         'downloaded': format(stampDownload, 'dd.MM.yyyy HH:mm')
//       }
//       if (!filterDevice && !filterDrivers) res.push(data)
//       else {
//         if (filterDevice && filterDevice.value === f.device && !filterDrivers) res.push(data)
//         if (filterDrivers && filterDrivers.value === f.driver && !filterDevice) res.push(data)
//         if (filterDrivers && filterDevice && filterDevice.value === f.device && filterDrivers.value === f.driver) res.push(data)
//       }
//     }
//   })
//   return res
// }

export function dddListFilesRows(files, stamp) {
  let res = []
  files.forEach((f) => {
    let stampDownload = parseISO(f.stamp);
    if (isStampInRange(stampDownload, stamp[0], stamp[1])) {
      const data = {
        'id': f.id,
        'kind': f.driver ? 'driver' : 'vehicle',
        'vehicle': f.dname,
        'driver': f.namedriver,
        'activityPeriod': f.stamp1 && f.stamp2 ? `${format(parseISO(f.stamp1), 'dd.MM.yyyy')} - ${format(parseISO(f.stamp2), 'dd.MM.yyyy')}` : '',
        'downloaded': format(stampDownload, 'dd.MM.yyyy HH:mm')
      }
      res.push(data)
    }
  })
  return res
}

export function dddListFilesRowsAll(files, page, pageSize) {
  let res = []
  files.list.forEach((f) => {
    let stampDownload = parseISO(f.stamp)
    const data = {
      'id': f.id,
      'kind': f.driver ? 'driver' : 'vehicle',
      'vehicle': f.dname,
      'driver': f.namedriver,
      'activityPeriod': f.stamp1 && f.stamp2 ? `${format(parseISO(f.stamp1), 'dd.MM.yyyy')} - ${format(parseISO(f.stamp2), 'dd.MM.yyyy')}` : '',
      'downloaded': format(stampDownload, 'dd.MM.yyyy HH:mm')
    }
    res.push(data);
  })

  if (files.list.length > 1) res.sort((a, b) => b.id - a.id);
  // fill empty rows via total count rows from server
  const emptyCount = files.count;
  const emptyArray = new Array(Math.max(0, emptyCount)).fill('');
  const startIndex = (page - 1) * pageSize;
  res = emptyArray.slice(0, startIndex).concat(res, emptyArray.slice(startIndex + res.length));
  return res
}