import React, { useState, useContext, useEffect, useRef, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import GridTable from '@nadavshaar/react-grid-table';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { client } from 'websocket';
import { masterUser } from "../../components/tools";
import { parseISO, format } from 'date-fns'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";

import { UserContext, isBrand, useWindowSize } from "../../components";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { statusstamp, statusstampSort, addressstamp } from '../../components/DevicesView/columnsDevicesView'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineSearch } from 'react-icons/ai'
import { IoMdArrowRoundBack } from "react-icons/io";
import Loading from "../../components/Loading";

const Excel = React.lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));

export default function SCard() {
    const login = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : JSON.parse(sessionStorage.getItem('login'))
    const navigate = useNavigate();
    document.title = 'sCard'

    const [sCardList, setSCardList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(false);
    const refWS29 = useRef(null);
    const height = useWindowSize().height;

    const { clientRoles, costumer } = useContext(UserContext);

    useEffect(() => {
        if (masterUser.includes(login.uname) && clientRoles.some(i => i.type.symbol === 'data_curator')) {
            if (!sCardList && !refWS29.current) {
                refWS29.current = true;
                WsSend({
                    method: 'getExportNode',
                    oid: 29,
                    type: 'sCard'
                });
                const handleMessage = (message) => {
                    let data = WsAnal(message)
                    if (data && data.oid === 29) {
                        setSCardList(WSdata(data, null, 29))
                        setLoading(false)
                        client.removeEventListener('message', handleMessage);
                    }
                }
                client.addEventListener('message', handleMessage, { once: true });
            }
        }
        else navigate('/')
    }, [sCardList])

    const columnssCard = [
        {
            id: 0,
            index: 0,
            field: 'index',
            width: 'auto',
            label: ' ',
            visible: true,
            sortable: false,
        },
        {
            id: 1,
            index: 1,
            field: 'client',
            width: 'auto',
            label: 'Č. zák.',
            visible: true,
            sortable: false,
        },
        {
            id: 2,
            index: 2,
            field: 'clientname',
            width: 'auto',
            label: 'Zákazník',
            visible: true,
            sortable: true,
        },
        {
            id: 3,
            index: 3,
            field: 'device',
            width: 'auto',
            label: 'Vozidlo',
            visible: true,
            sortable: true,
        },
        {
            id: 4,
            index: 4,
            field: 'astamp',
            label: 'Poslední změna',
            width: 'auto',
            visible: true,
            sortable: true,
            searchable: false,
            cellRenderer: statusstamp,
            sort: statusstampSort,
        },
        {
            id: 5,
            index: 5,
            field: 'expireson',
            label: 'Expirace karty',
            width: 'auto',
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                let aStamp = parseISO(value);
                return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className='rgt-text-truncate'>
                            {format(aStamp, 'dd.MM.yyyy')}
                        </span>
                    </div>
                )
            },
            sort: statusstampSort,
        },
        {
            id: 6,
            index: 6,
            field: 'text',
            label: 'Poznámky',
            width: '150px',
            sortable: true,
        },
        {
            id: 7,
            index: 7,
            field: 'server',
            label: 'Server',
            width: 'auto',
            sortable: true,
        },
        {
            id: 8,
            index: 8,
            field: 'id',
            label: 'Id',
            width: 'auto',
            sortable: true,
        },
        {
            id: 9,
            index: 9,
            field: 'isavailable',
            label: 'Stav',
            width: 'auto',
            sortable: true,
            searchable: false,
            cellRenderer: ({ value }) => {
                return (
                    <div className='rgt-cell-inner rgt-cell-component'>
                        <span className={`rgt-text-truncate d-block rounded-circle ${value === 'true' ? 'bg-success' : 'bg-danger'}`} style={{ height: 20, width: 20 }}>
                        </span>
                    </div>
                )
            },
        },
    ]

    const getRows = ((r) => {
        if (!r) return null
        // console.log(d);
        return r.map((d, index) => {
            return ({
                'index': (index + 1).toString(),
                'id': d.id.toString(),
                'client': d.client.toString(),
                'clientname': d.clientname,
                'astamp': d.astamp,
                'expireson': d.expireson,
                'server': d.server,
                'isavailable': d.isavailable,
                'device': d.device?`${d.plate} [${d.device}]`:'',
                'text': d.text?JSON.parse(d.text):''
            })
        })
    })

    if (!loading) return (
        <div className="config" style={{ minHeight: '100vh' }}>
            <div className='gridHandle d-flex justify-conten-evenly'>
                <div className="back">
                    <Link to="/admin">
                        <IoMdArrowRoundBack className="fs-5 mx-2 text-secondary my-1 ms-2" />
                    </Link>
                </div>
                <span className={`ms-2 gridHandleOn`}>sCard</span>
                <span className="m-auto">  </span>
                <Suspense >
                    <span style={{ position: 'fixed', right: '15%', paddingRight: '70px' }}>
                        <Excel
                            excelData={getRows(sCardList)}
                            fileName={'sCardList'}
                            clientName={costumer}
                            exportCsv={true}
                            classStyle={'mx-1 p-0 px-1'} />
                        <Excel
                            excelData={getRows(sCardList)}
                            fileName={'sCardList'}
                            clientName={costumer}
                            exportCsv={false}
                            classStyle={'mx-1 p-0 px-1'} />
                    </span>
                </Suspense >
            </div>
            <div className="d-flex justify-content-center">
                <GridTable
                    style={{ minHeight: 'unset', height: (height - 30) + 'px' }}
                    className={'sCard-table'}
                    columns={columnssCard}
                    rows={getRows(sCardList)}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: ''
                    }}
                    icons={{ search: <AiOutlineSearch /> }}
                    highlightSearch={true}
                    onRowClick={({ data }) => {
                        setOpen(data)
                    }}
                />
            </div>
            {open ? (
                <Modal size='lg' fullscreen='sm-down' centered show={open} onHide={() => { setOpen(false); setText(false)}} >
                    <Modal.Header closeButton >
                        <Modal.Title>{open.clientname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            
                            WsSend({
                                method: 'getExportNode',
                                oid: 29,
                                type: 'sCardUpdate',
                                device: JSON.stringify(text.replace(/\r?\n/g, '\\r')),
                                stampTo: open.id,
                                stampFrom: open.server
                            });
                            setLoading(true)
                            const handleMessage = (message) => {
                                let data = WsAnal(message)
                                // console.log('new data sCard list', data);
                                if (data && data.oid === 29) {
                                    client.removeEventListener('message', handleMessage);
                                    if(data.value === 1){
                                        setOpen(false)
                                        refWS29.current= null;
                                        setSCardList(null)
                                    }
                                    else navigate('/error')
                                }
                            }
                            client.addEventListener('message', handleMessage, { once: true });
                        }}>
                            <div className="d-flex flex-column align-items-center">
                                <FloatingLabel className="w-75 my-2" label={'Poznámky'}>
                                    <Form.Control type="text" defaultValue={open.text} style={{minHeight: '250px'}} as="textarea" onChange={(e) => { setText(e.target.value)
                                     }} />
                                </FloatingLabel>

                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <Button disabled={text?false:true} className={false ? "w2-5" : "w-50 " + "max-w-50vh mb-3 my-2 btn btn-dark"} type="submit">{
                                    false ? <Spinner animation="border" role="status"><span className="visually-hidden">Nahrávám</span></Spinner> : 'Uložit'
                                }</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>) : ''}
        </div>
    )
    else return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
}