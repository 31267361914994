import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { client } from "websocket";
import { Trans } from 'react-i18next';
import { Button } from "react-bootstrap"

import { WsLogout } from "../scripts/ws";
import { Logo } from "../images";
import errorPicture from "../images/error_500.jpg"

export default function ({ page }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            if (client) {
                client.onopen = () => {
                    navigate(page ? page : '/overview');
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                };
            }
            else {
                navigate('/');
            }
        }
    }, [client])
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", height: '100vh' }}>
                <h1 style={{ fontWeight: 'bold' }}>Internal Server Error</h1>
                <h1 className='ws-bold' style={{ fontWeight: 'bold' }}>500</h1>
                <img src={errorPicture} alt="error 500" style={{ height: '200px' }} />
                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Trans i18nKey="page.errorText" />
                </div>
                <Logo style={{ width: '250px', marginTop: '2rem' }} title="LOGISCARE" />
                <Button variant="link" className='mt-5' onClick={() => {
                    WsLogout();
                    setTimeout(() => {
                        navigate('/')
                    }, 200);
                }}><Trans i18nKey="widget.logout" /></Button>
            </div>
        </div>
    )
}