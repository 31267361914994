import React, { useState, forwardRef, useContext, useEffect, useRef } from "react";
import { client } from 'websocket';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from 'react-i18next';
import { format, subYears } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';

import { GrFormClose } from 'react-icons/gr'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'
import Loading from "../../components/Loading";

import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { DDDViewVehicle, DDDViewDrivers, DDDViewListFiles, useWindowSize, setQuery, getQuery, clearQuery } from '../../components';
import { UserContext } from "../../components/userDetails";
import { newLayots, checkMount, filterLayouts, margin, breakpoints, cols, HeaderGrid, calcMaxH, rowHeight } from "../gridLayout";

import { layoutTachoDefault, layoutNew } from "./layoutTacho";

const ResponsiveGridLayout = WidthProvider(Responsive);

var DDDViewVehicleHeight, DDDViewDriversHeight, DDDViewListFilesHeight, DDDViewListFilesWidth, DDDViewDriversWidth, DDDViewVehicleWidth;

const GridItemComponent = forwardRef(({ style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props }, ref) => {
  if (props.max === props.id) {
    style.height = props.heightWindow + 'px';
    style.width = "98.5%";
    style.transform = 'translate(10px, 10px)'
    style.transition = 'all 200ms ease'
  }
  else {
    if (props.max) style.display = 'none';
  }

  if (props.id === 'DDDViewVehicle') {
    DDDViewVehicleHeight = style.height.slice(0, -2);
    DDDViewVehicleWidth = style.width.slice(0, -2);
  }

  if (props.id === 'DDDViewDrivers') {
    DDDViewDriversHeight = style.height.slice(0, -2);
    DDDViewDriversWidth = style.width.slice(0, -2);
  }

  if (props.id === 'DDDViewListFiles') {
    DDDViewListFilesHeight = style.height.slice(0, -2);
    DDDViewListFilesWidth = style.width.slice(0, -2);
  }


  return (
    <div style={{ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
      {children}
    </div>
  );
})


export default function Tacho({ forceUpdate, widgetOnDrop, setWidgetOnDrop, defaultLayout }) {
  const { costumer, dev, driv } = useContext(UserContext)
  const [devices, setDevices] = useState(dev);
  const [drivers, setDrivers] = useState(driv);
  const [filesInfo, setFilesInfo] = useState(false);
  const [files, setFiles] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'widget' });
  const navigate = useNavigate();
  const location = useLocation();
  const [filesAll, setFilesAll] = useState(false);
  const [fileDownload, setFileDownload] = useState(false);

  const [updateItem, setUpdateItem] = useState();
  const heightWindow = useWindowSize().height;
  const lockLayout = useContext(UserContext).lockLayout.tacho;
  const uiConfig = useContext(UserContext).uiConfig;
  const sideBar = useContext(UserContext).uiConfig.sideBar;
  const layoutTacho = useContext(UserContext).uiConfig.layoutTacho;
  const listClientSearch = useContext(UserContext).listClientSearch

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingFilesInfo, setLoadingFilesInfo] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);

  const { setUiConfig, callChangeUiConfig, setListClientSearch, setDriv, setDev } = useContext(UserContext);
  const refRender = useRef(null);
  const refWS4 = useRef(null);
  const refWS9 = useRef(null);
  const refWS16 = useRef(null);
  const refWS12 = useRef(null);
  document.title = t('tacho')

  client.onmessage = (message) => {
    let data = WsAnal(message)
    console.log('new data Tacho', data);
    // update message
    if (data && data.length > 0) {
      setDevices(WSdata(data, devices, 4));
      setFiles(WSdata(data, files, 9));
      setListClientSearch(WSdataList(data, listClientSearch, 11));
      setFilesAll(WSdataList(data, filesAll, 21));
    }
    // first new message from query
    else {
      if (data) {
        if (data.id === 4) {
          const res = WSdata(data, null, 4).list;
          setDevices(res);
          setDev(res);
          setLoadingPage(false);
        }
        if (data.id === 9) setFiles(WSdata(data, null, 9).list)
        if (data.id === 21) {
          setFilesAll(WSdata(data, null, 21))
          setLoadingFiles(false);
        }
        if (data.oid === 12) {
          setFilesInfo(WSdata(data, null, 12));
          setLoadingFilesInfo(false)
        }
        if (data.oid === 10) setOpenFile(WSdata(data, null, 10));
        if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
        if (data.id === 16) {
          const res = WSdata(data, null, 16).list;
          setDrivers(res);
          setDriv(res);
        }
        if (data.oid === 25) {
          if (data.value) {
            let url = '/' + data.value;
            if (window.location.hostname === 'localhost') {
              const ws = client.url.split(':');
              const urlPrefix = ws[0] === 'wss' ? 'https:' : 'http:';
              url = urlPrefix + ws[1] + data.value;
            }
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.click();
          }
        }
      }
    }
    setTimeout(() => {
      if (loadingFiles) setLoadingFiles(false);
    }, 5000);
  }

  useEffect(() => { callChangeUiConfig.current = 0 }, [])

  useEffect(() => {
    // console.log('open file', openFile);
    if (typeof openFile === 'number') {
      WsSend({ method: 'getFile', oid: 10, id: openFile });
      setOpenFile([])
    }
  }, [openFile])

  const [layout, setLayout] = useState();
  const [itemMax, setItemMax] = useState();

  if (!devices && !refWS4.current) {
    refWS4.current = true;
    WsSend({ method: 'getDevices', oid: 4, restriction: 'edit_maintenances' });
  }

  if (!drivers && !refWS16.current) {
    refWS16.current = true;
    WsSend({ method: 'getDrivers', oid: 16 });
  }

  useEffect(() => {
    if (filesInfo) {
      setFilesInfo(false);
      refWS12.current = false;
    }
    if (!dev) {
      setDevices(dev);
      setDrivers(driv);
      refWS4.current = false;
      refWS16.current = false;
    }
  }, [costumer])

  const resize = (() => {
    setTimeout(() => {
      setUpdateItem(!updateItem)
    }, 10);
  })

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setUpdateItem(!updateItem);
    }, 400);
  }, [sideBar])

  useEffect(() => {
    if (defaultLayout && defaultLayout[1] === '/tacho') {
      setLayout(!lockLayout ? calcMaxH(layoutTachoDefault, heightWindow) : layoutTachoDefault)
    }
  }, [defaultLayout])


  const removeWidget = (removeItem) => {
    const res = filterLayouts(removeItem, layout)
    setLayout(res);
    setUiConfig({ ...uiConfig, layoutTacho: res });
  }

  useEffect(() => {
    if (!layout) {
      const l = layoutTacho ? layoutTacho : layoutTachoDefault;
      setLayout(!lockLayout ? calcMaxH(l, heightWindow) : l)
    }
    if (!lockLayout && refRender.current && layout) {
      setTimeout(() => {
        const newLayout = calcMaxH(layout, heightWindow);
        setLayout(newLayout)
      }, 100)
    } else refRender.current = true;
  }, [heightWindow])

  if (layout && (checkMount('DDDViewDrivers', layout) || checkMount('DDDViewVehicle', layout))) {
    if (!files && !refWS9.current) {
      refWS9.current = true;
      const lastTwo = format(subYears(new Date(), 2), 'yyyy-MM-dd HH:mm');
      WsSend({
        method: 'getFiles',
        ddd: localStorage.getItem('isddd') === 'false' ? null : true,
        device: 1,
        oid: 9,
        stampFrom: lastTwo,
        pageSize: 5000
      });
    }
    if (!refWS12.current && !filesInfo) {
      refWS12.current = true;
      WsSend({ method: 'getFileInfo', oid: 12 });
    }

  }

  useEffect(() => {
    if (widgetOnDrop) {
      let newLayouts = newLayots(layoutNew(widgetOnDrop), layout);
      setLayout(newLayouts)
      setUiConfig({ ...uiConfig, layoutTacho: newLayouts });
      setTimeout(() => {
        setWidgetOnDrop(null)
      }, 200);
    }
  }, [widgetOnDrop])

  useEffect(() => {
    if (devices && refWS4.current) WsSend({ method: 'unregister', id: 4 });
  }, [devices])

  useEffect(() => {
    if (drivers && refWS16.current) WsSend({ method: 'unregister', id: 16 });
  }, [drivers])

  useEffect(() => {
    if (devices) setLoadingPage(false);
  }, [])

  useEffect(() => {
    const fullScreen = getQuery('fullScreen', location)
    if (fullScreen) setItemMax(fullScreen)
  }, [location.search]);

  useEffect(() => {
    if (itemMax) setQuery('fullScreen', itemMax, navigate, location)
    else clearQuery(navigate)
  }, [itemMax])

  useEffect(() => {
    if (fileDownload && openFile && openFile.contents) {
      const uint8Array = new Uint8Array(openFile.contents);
      const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = openFile.name;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setFileDownload(false)
      setOpenFile(false)
    }
  }, [openFile])



  if ((devices && devices.length > 0 && !loadingPage)) {
    return (
      <>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          margin={margin}
          breakpoints={breakpoints}
          cols={cols}
          onBreakpointChange={(e) => { console.log('layout breakpoint', e); }}
          onLayoutChange={(items, layouts) => {
            setLayout(layouts);
            setUiConfig({ ...uiConfig, layoutTacho: layouts });
          }}
          isDraggable={!lockLayout && !itemMax}
          isResizable={!lockLayout && !itemMax}
          rowHeight={rowHeight}
          compactType={'vertical'}
          onResizeStop={resize}
          resizeHandles={['se']}
          draggableHandle=".gridHandleOn"
          isDroppable={false}
          style={{ minHeight: '90%', height: '100vh' }}
        >

          {checkMount('DDDViewVehicle', layout) ? <GridItemComponent key='DDDViewVehicle' id={"DDDViewVehicle"} max={itemMax} heightWindow={lockLayout ? (heightWindow - 20) : (heightWindow - 70)}>
            <div>
              <HeaderGrid name={"DDDViewVehicle"} title={t('dddFileVehicle')} height={DDDViewVehicleHeight - 24} width={DDDViewVehicleWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {DDDViewVehicleHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DDDViewVehicle
                  devices={devices}
                  filesInfo={filesInfo}
                  files={files}
                  openFile={openFile}
                  setOpenFile={setOpenFile}
                  height={DDDViewVehicleHeight - 24}
                  lock={lockLayout}
                  loading={loadingFilesInfo}
                  setFileDownload={setFileDownload}
                />
              </div> : ''}
            </div>
          </GridItemComponent> : ''}

          {checkMount('DDDViewDrivers', layout) ? <GridItemComponent key='DDDViewDrivers' id={"DDDViewDrivers"} max={itemMax} heightWindow={lockLayout ? (heightWindow - 20) : (heightWindow - 70)}>
            <div>
              <HeaderGrid name={"DDDViewDrivers"} title={t('dddFileDrivers')} height={DDDViewDriversHeight - 24} width={DDDViewDriversWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {DDDViewDriversHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DDDViewDrivers
                  filesInfo={filesInfo}
                  drivers={drivers}
                  files={files}
                  openFile={openFile}
                  setOpenFile={setOpenFile}
                  height={DDDViewDriversHeight - 24}
                  lock={lockLayout}
                  setFileDownload={setFileDownload}
                  loading={loadingFilesInfo}
                />
              </div> : ''}
            </div>
          </GridItemComponent> : ''}

          {checkMount('DDDViewListFiles', layout) ? <GridItemComponent key='DDDViewListFiles' id={"DDDViewListFiles"} max={itemMax} heightWindow={lockLayout ? (heightWindow - 20) : (heightWindow - 70)}>
            <div>
              <HeaderGrid name={"DDDViewListFiles"} title={t('dddFilesList')} height={DDDViewListFilesHeight - 24} width={DDDViewListFilesWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} />
              {DDDViewListFilesHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DDDViewListFiles
                  filesAll={filesAll}
                  setFiles={setFilesAll}
                  devices={devices}
                  drivers={drivers}
                  modal={false}
                  openFile={openFile}
                  setOpenFile={setOpenFile}
                  height={DDDViewListFilesHeight - 24}
                  width={DDDViewListFilesWidth}
                  max={itemMax}
                  lock={lockLayout}
                  setFileDownload={setFileDownload}
                  loading={loadingFiles}
                  setLoading={setLoadingFiles}
                />
              </div> : ''}
            </div>
          </GridItemComponent> : ''}

        </ResponsiveGridLayout>
        {openFile.length === 0 ? <div className="loading-background" ><Loading className="loading" /></div> : ''}
      </>
    )
  }
  else {
    return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
  }
}