import { parseISO, format, formatISO, sub, intervalToDuration } from 'date-fns'
import i18n from '../../dictionary/i18n';

import { GrMap } from 'react-icons/gr'
import { PiEngine } from "react-icons/pi";
import { LiaGasPumpSolid } from "react-icons/lia";
import { isClient } from '../tools';

export function formatDistance(val) {
    return val.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
}

export function formatDecimalSeparator(val) {
    return val.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 })
}

export function formatDurationOver24(val, hideUnits) {
    const diffInSec = Math.floor(val / 1000);
    const dur = intervalToDuration({ start: 0, end: diffInSec * 1000 });
    const hours = dur.hours || dur.days ? (dur.days > 0 ? (dur.days * 24) + dur.hours : dur.hours).toString().padStart(2, '0') : '00';
    const minutes = dur.minutes ? (dur.minutes).toString().padStart(2, '0') : '00';

    return (hours + ':' + minutes + (hideUnits ? '' : ' h'))
}


const prepareExport = (data, columns) => {
    const newData = {}
    columns.forEach(col => {
        if (col.visible && col.field !== 'checkbox') {
            switch (col.field) {
                case 'stamp': {
                    if (data[col.field] !== '') {
                        const stamp = data[col.field].split(';');
                        newData[col.label] = format(parseISO(stamp[0]), 'dd.MM.yyyy');
                        newData[i18n.t('stats.day')] = stamp[2];
                    } else {
                        newData[col.label] = '';
                        newData[i18n.t('stats.day')] = '';
                    }
                }
                    break;
                case 'time': {
                    const time = data[col.field].split(';');
                    newData[col.label.split(';')[0]] = time[0];
                    newData[col.label.split(';')[1]] = time[1];
                }
                    break;
                case 'text': {
                    const place = data[col.field].split(';');
                    newData[col.label.split(';')[0]] = place[0];
                    newData[col.label.split(';')[1]] = place[1];
                }
                    break;
                case 'driver_purpose': {
                    const driver_purpose = data[col.field].split(';');
                    if (col.label.includes(';')) {
                        newData[col.label.split(';')[1]] = driver_purpose[1];
                        newData[col.label.split(';')[0]] = driver_purpose[0];
                    }
                    else newData[col.label] = driver_purpose[1]
                }
                    break;
                case 'purpose_array': {
                    const purpose_array = data[col.field].split(';');
                    newData[col.label.split(';')[0]] = purpose_array[0];
                    newData[col.label.split(';')[1]] = purpose_array[1];
                }
                    break;
                case 'fuel': {
                    // const fuel = data[col.field].split(';');
                    newData[col.label.split(';')[0] + '\n[l]'] = data['fuelNumber']
                    newData[col.label.split(';')[1] + '\n[l/100km]'] = data['fuelAvrNumber']
                }
                    break;
                case 'distance': newData[col.label + '\n[km]'] = data['distanceNumber'];
                    break;
                case 'duration': newData[col.label + '\n[h]'] = data[col.field].split(' ')[0];
                    break;
                default: newData[col.label] = data[col.field]
                    break;
            }

        }
    })
    return newData
}

export function ridesRows(rides, rowOpen, search, isAscending, driver, exportExcel, hideColumns) {
    // console.log(rides);
    if (!rides) return null
    let res = [];
    function setRows(res, d, rowOpen, search) {
        // console.log(d);
        //stamp
        const stamp1 = parseISO(d.stamp1)
        const stamp2 = parseISO(d.stamp2)
        const time1 = format(stamp1, 'HH:mm');
        const time2 = format(stamp2, 'HH:mm');
        const day = format(stamp1, 'eeeeee').charAt(0).toUpperCase() + format(stamp1, 'eeeeee').slice(1);
        const date = d.stamp1.split("T")[0]
        const distance = (d.distance ? formatDistance(d.distance) + ' ' + d.units : '') + (d.distancep ? `;${formatDistance(d.distancep)} ${d.units}` : '');
        const distanceVal = d.distance + d.distancep;
        const duration = formatDurationOver24(d.duration)
        const fuel = d.fuel > 10 ? d.fuel : d.cons > 10 ? d.cons : 0;
        const average_fuel = (fuel / 1000) > 0.1 ? ((100 / distanceVal) * (fuel / 1000)) : 0;
        //rows for total day
        if (!search) {
            let checkStamp2 = d.stamp2;
            if (format(stamp2, 'HH:mm:ss').toString() === '00:00:00') checkStamp2 = formatISO(new Date(sub(stamp2, { days: 1 })));
            const values = {
                'id': Array.isArray(d.rideIds) ? d.rideIds.join(',') : d.rideIds.toString(),
                'stamp': d.stamp1 + ';' + checkStamp2 + ';' + day + ';' + d.ride.length + ';' + rowOpen.includes(date),
                'time': time1 + ';' + time2,
                'text': d.text1 + ';' + d.text2,
                'distance': distance,
                'duration': duration,
                'driver_purpose': `${d.purpose.join(', ')};${driver ? d.plate : (d.drivername ? d.drivername : '')}`,
                'purpose_array': d.purpose.length >= 1 && d.purpose[0] ? d.purpose[0].split('|').join(';') : '',
                'fuel': formatDecimalSeparator(fuel / 1000) + d.fuelUnits + ';' + (distanceVal > 3 ? (formatDecimalSeparator(average_fuel) + d.fuelUnits + '/100' + d.units) : ''),
                'type': d.type.map(t => t ? i18n.t('stats.isprivate') : i18n.t('stats.bussines')).join(exportExcel ? ', ' : ';')
            };
            if (exportExcel) {
                let columns = exportExcel;
                if (hideColumns.length > 0) columns = exportExcel.filter(c => !hideColumns.some(hc => hc.id === c.id));
                let distance = 0
                if (d.distance > 0) distance = parseFloat(d.distance.toFixed(1))
                if (d.distancep > 0) {
                    if (distance > 0) distance = distance + ', ' + parseFloat(d.distancep.toFixed(1));
                    else distance = parseFloat(d.distancep.toFixed(1));
                }
                values['fuelNumber'] = fuel / 1000;
                values['distanceNumber'] = distance;
                values['fuelAvrNumber'] = distanceVal > 3 ? average_fuel : 0;
                res.push(prepareExport(values, columns))
            } else res.push(values)
        }

        //rows for all rides on day
        if (search || rowOpen.includes(date)) {
            for (let ii = d.ride.length - 1; ii >= 0; ii--) {
                const r = d.ride[ii];
                //stamp          
                const stamp1 = parseISO(r.stamp1)
                const stamp2 = parseISO(r.stamp2)
                const time1 = format(stamp1, 'HH:mm');
                const time2 = format(stamp2, 'HH:mm');
                const day = format(stamp1, 'eeeee').charAt(0).toUpperCase() + format(stamp1, 'eeeee').slice(1);
                const distance2 = formatDistance(r.distance) + ' ' + r.units;
                const duration2 = formatDurationOver24((new Date(r.stamp2) - new Date(r.stamp1)))
                const fuel = r.fuel > 10 ? r.fuel : r.cons > 10 ? r.cons : 0;
                const average_fuel = (fuel / 1000) > 0.1 ? ((100 / r.distance) * (fuel / 1000)) : 0;
                const values = {
                    'id': r.id.toString(),
                    'stamp': search ? r.stamp1 + ';' + r.stamp2 + ';' + day + ';' + 1 : '',
                    'time': time1 + ';' + time2,
                    'text': r.text1 + ';' + r.text2,
                    'distance': distance2,
                    'duration': duration2,
                    'driver_purpose': `${r.purpose !== null ? r.purpose : ''};${driver ? r.plate : (r.drivername ? ((r.drivername + (isClient('develop', '[1]') && r.drivermark ? ` ${r.drivermark}` : '')) + (r.driver2name ? ' / ' + r.driver2name : '')) : '')}`,
                    'purpose_array': r.purpose && r.purpose.length >= 1 ? r.purpose.split('|').join(';') : '',
                    'fuel': formatDecimalSeparator(fuel / 1000) + r.fuelUnits + ';' + (r.distance > 3 ? (formatDecimalSeparator(average_fuel) + r.fuelUnits + '/100' + r.units) : ''),
                    'type': r.isprivate ? i18n.t('stats.isprivate') : i18n.t('stats.bussines')
                }
                if (exportExcel) {
                    let columns = exportExcel;
                    if (hideColumns.length > 0) columns = exportExcel.filter(c => !hideColumns.some(hc => hc.id === c.id));
                    values['fuelNumber'] = fuel / 1000;
                    values['distanceNumber'] = r.distance > 0 ? parseFloat(r.distance.toFixed(1)) : 0;
                    values['fuelAvrNumber'] = r.distance > 3 ? average_fuel : 0;
                    res.push(prepareExport(values, columns))
                }
                else res.push(values)
            }
        }
    }
    if (isAscending) {
        for (let i = rides.length - 1; i >= 0; i--) {
            let d = rides[i];
            setRows(res, d, rowOpen, search)
        }
    }
    else {
        for (let i = 0; i < rides.length; i++) {
            let d = rides[i];
            setRows(res, d, rowOpen, search)
        }
    }
    return res
}

export function checkRows(newArr, prevArr, masterDelete) {
    // console.log('start checkRows', newArr);
    // console.log('prevArr checkRows', prevArr);

    let editArr = newArr;

    //find item which is mistake
    const deleteItem = prevArr.filter((item) => !editArr.includes(item));
    // console.log('deleteItem', deleteItem);

    // push from item "1324,5678" like "1324" and "5678" and check if not exist
    editArr.forEach((ids) => {
        if (ids.includes(',')) {
            ids.split(',').forEach((id) => {
                const check = ((id) => {
                    let res = true;
                    newArr.forEach((i) => {
                        if (i === id) res = false
                    })
                    return res
                })
                if (check(id)) editArr.push(id.toString())
            })
        }
    })

    // if day off delete all rides on this day
    // if deleteItem not length === 1 detele caused by close dayRow
    if (deleteItem.length === 1 && deleteItem.toString().includes(',')) {
        editArr = editArr.filter((el) => !deleteItem.toString().split(',').some((item) => el.includes(item)))
    }
    else {
        //filter or item like deleteItem
        if (deleteItem > 0 && editArr.includes(deleteItem.toString())) {
            editArr = editArr.filter(item => !item.includes(deleteItem.toString()));
        }
    }

    //checkbox on header using ref
    if (masterDelete) {
        if (masterDelete[0]) {
            editArr = []
        }
        masterDelete[0] = false;
    }

    // console.log('final checkRows', editArr);
    return editArr
}

export function parseIdsRides(arrString) {
    // console.log(arrString);
    let arrIds = []
    arrString.forEach((id) => {
        if (!id.includes(',')) arrIds.push(parseInt(id));
    })
    // console.log(arrIds);
    return arrIds
}

export function pluralize(number, singular, plural1, plural2) {
    if (number === 1) {
        return `${number} ${singular}`;
    } else if (number >= 2 && number <= 4) {
        return `${number} ${plural1}`;
    } else {
        return `${number} ${plural2}`;
    }
}

export function information(table, stats, statsRowsSelected) {
    if (!stats) return null
    if (stats && stats.totalDist === 0) return null
    let selectedRides = [];
    if (table.tableManager.rowSelectionApi.selectedRowsIds.length > 0) {
        table.tableManager.rowSelectionApi.selectedRowsIds.forEach((item) => {
            if (!item.includes(',')) selectedRides.push(item)
        })
    }

    const dur = intervalToDuration({ start: 0, end: stats.weeklyDur * 1000 });
    const durPriv = stats.weeklyDurPriv ? intervalToDuration({ start: 0, end: stats.weeklyDurPriv * 1000 }) : null;
    const hours = (dur.days > 0 ? (dur.days * 24) + dur.hours : dur.hours).toString().padStart(2, '0');
    const minutes = (dur.minutes).toString().padStart(2, '0');
    const hoursPriv = durPriv ? ((durPriv.days > 0 ? (durPriv.days * 24) + durPriv.hours : durPriv.hours).toString().padStart(2, '0')) : '00';
    const minutesPriv = durPriv ? (durPriv.minutes).toString().padStart(2, '0') : '00';
    const fuel = stats.weeklyFuel;
    const fuelPriv = stats.weeklyFuelPriv;
    const cons = stats.weeklyCons;
    const consPriv = stats.weeklyConsPriv;
    const average_fuel = ((100 / (stats.weeklyDist + stats.weeklyDistPriv)) * ((fuel + fuelPriv) / 1000));
    const average_cons = ((100 / (stats.weeklyDistance + stats.weeklyDistancePriv)) * ((stats.weeklyCons + stats.weeklyConsPriv) / 1000));

    // const average_fuelPriv = (fuelPriv / 1000) > 0.1 ? ((100 / stats.weeklyDistPriv) * (fuelPriv / 1000)) : 0;
    const fuelType = ((fuel / 1000) > 0.1 ? 'can' : 'fueling')
    const fuelTypePriv = ((fuelPriv / 1000) > 0.1 ? 'can' : 'fueling')

    return (
        <div className="rgt-footer-items-information">
            <span> {durPriv ? i18n.t('stats.summaryBussines') : i18n.t('stats.summary')} <br />
                {durPriv ? (<span>{i18n.t('stats.summaryIsprivate')}</span>) : ''}
            </span>
            <span> {pluralize(stats.dailyCount, i18n.t('stats.one_ride'), i18n.t('stats.two_rides'), i18n.t('stats.many_rides'))}
                {table.selectedCount === 0 ? '' : <span> | <GrMap className="mb-1" /> {pluralize(selectedRides.length, i18n.t('stats.one_ride'), i18n.t('stats.two_rides'), i18n.t('stats.many_rides'))}
                    {statsRowsSelected.distance > 0 && statsRowsSelected.duration > 0 ? <span> {`(${formatDistance(statsRowsSelected.distance)} ${stats.units}`} {`${formatDurationOver24(statsRowsSelected.duration)})`}
                    </span> : ''}
                </span>}</span>
            <span> {hours + ':' + minutes + ' ' + i18n.t('stats.hours')} <br />
                {durPriv && durPriv.minutes > 0 ? hoursPriv + ':' + minutesPriv + ' ' + i18n.t('stats.hours') : ''}
            </span>
            <span title={`Celkem: ${formatDistance(stats.weeklyDist + (stats.weeklyDistPriv ? stats.weeklyDistPriv : 0))} km`}> {formatDistance(stats.weeklyDist)} {stats.units} <br />{stats.weeklyDistPriv ? `${formatDistance(stats.weeklyDistPriv)} ${stats.units}` : ''}</span>
            {
                fuel + fuelPriv > 0 || cons + consPriv > 0 ? (<>
                    <span title={i18n.t('stats.totalFuel') + '\n' + i18n.t('stats.sourceFuel_' + fuelType)}>
                        {fuelType === 'can' ? (<span>
                            <PiEngine className='me-1 mb-1' /> {`${formatDecimalSeparator(fuel / 1000)} ${stats.fuelUnits} `}
                        </span>) : (fuelType === 'fueling' ? (<span>
                            <LiaGasPumpSolid className='me-1 mb-1' /> {`${formatDecimalSeparator(cons / 1000)} ${stats.fuelUnits} `}
                        </span>) : '')}
                        <br />
                        {fuelPriv > 0 || consPriv > 0 ? (<>
                            {fuelTypePriv === 'can' ? (<span>
                                <PiEngine className='me-1 mb-1' /> {`${formatDecimalSeparator(fuelPriv / 1000)} ${stats.fuelUnits} `}
                            </span>) : (fuelTypePriv === 'fueling' ? (<span>
                                <LiaGasPumpSolid className='me-1 mb-1' /> {`${formatDecimalSeparator(consPriv / 1000)} ${stats.fuelUnits} `}
                            </span>) : '')}
                        </>) : ''}
                    </span>
                    <span>{`Ø ${formatDecimalSeparator((average_fuel > 0 ? average_fuel : average_cons))}${stats.fuelUnits} /100${stats.units}`}</span >
                </>) : ''
            }
        </div >
    )
}

export function Loader() {
    const color = getComputedStyle(document.documentElement).getPropertyValue('--bs-primary');
    return (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
            <div className="loadingio-spinner-ring">
                <div className="ldio">
                    <div style={{ borderColor: `transparent ${color} transparent ${color}` }}></div>
                </div>
            </div>
        </div>
    )
}


