import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Button, Container, Dropdown, DropdownButton, Form } from "react-bootstrap"
import { Link } from "react-router-dom";
import { client } from 'websocket';

import { UserContext , isBrand} from "../../components";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { IoIosArrowForward } from "react-icons/io";
import { CarsGroup } from "../../images"
import { TbLayoutDashboard } from "react-icons/tb";
import { MdOutlineMailOutline,MdDevices } from "react-icons/md";

import './Config.css'


export default function Config() {
    const { t, i18n } = useTranslation('translation');
    const [unit, setUnit] = useState(localStorage.getItem('unit') && localStorage.getItem('unit') === 'f' ? true : false);

    document.title = i18n.t('widget.config')

    const { setDev, setListClientSearch, listClientSearch, clientGroups, clientRoles, setNotification, driv } = useContext(UserContext);

    client.onmessage = (message) => {
        let data = WsAnal(message)
        // console.log('new data', data);

        if (data) {
            // update message
            if (data.length > 0) {
                setListClientSearch(WSdataList(data, listClientSearch, 11));
            }

            // first new message from query
            else {
                if (data) {
                    if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
                    if (data.id === 26) setNotification(WSdata(data, null, 26));
                }

            }
        }
    }

    return (
        <div className="bg-white d-flex align-items-center justify-content-center config" style={{ minHeight: '100vh' }}>
            <div className="w-50 d-flex flex-column align-items-center shadow mb-5 bg-body rounded">
                <div className="d-flex w-100"><h3 className="my-4 ms-5 ps-3">{t('page.settings')}</h3></div>
                {isBrand('alfakomtemp') ? (
                    <div className="d-flex w-100 px-4 mb-2">
                        <div className="ms-1 fs-5 text">{t('unitSystem')}</div>
                        <div className="m-auto"></div>
                        <div className="ms-5 d-flex flex-row my-2">
                            <Form.Check
                                className="me-4 cursorpointer"
                                type="radio"
                                label={`°C`}
                                checked={!unit}
                                onChange={() => { setUnit(false); localStorage.removeItem('unit') }}
                            />
                            <Form.Check
                                className="me-4 cursorpointer"
                                type="radio"
                                label={`°F`}
                                checked={unit}
                                onChange={() => { setUnit(true); localStorage.setItem('unit', 'f') }}
                            />
                        </div>
                    </div>) : <></>}
                {clientRoles.some(i => i.type.symbol === 'configurator') ?
                    <Link to="/config/equipments" className="w-100  text-decoration-none">
                        <Button variant="light" className="d-flex align-items-center w-100 px-4">
                            <div className="icon">
                            {isBrand('alfakomtemp')?<MdDevices className="mx-2 fs-3 text-primary" />:
                                <CarsGroup height={24} className="mx-2 text-primary" style={{ fill: getComputedStyle(document.documentElement).getPropertyValue('--bs-primary') }} />}
                            </div>
                            <div className="ms-4 fs-5 text">{t('page.equipments')}</div>
                            <div className="m-auto"></div>
                            <div className="icon"><IoIosArrowForward className="fs-1 mx-2 text-secondary" /></div>
                        </Button>
                    </Link> : <></>}
                {!isBrand('alfakomtemp') ? (
                    <Link to="/config/dashboards" className="w-100  text-decoration-none">
                        <Button variant="light" className="d-flex align-items-center w-100 px-4">
                            <div className="icon"><TbLayoutDashboard className="mx-2 fs-3 text-primary" /></div>
                            <div className="ms-4 fs-5 text">{t('page.dashboards')}</div>
                            <div className="m-auto"></div>
                            <div className="icon"><IoIosArrowForward className="fs-1 mx-2 text-secondary" /></div>
                        </Button>        
                    </Link>) : <></>}

                {typeof driv !== 'number' ? (<Link to="/config/fences" className="w-100  text-decoration-none">
                    <Button variant="light" className="d-flex align-items-center w-100 px-4">
                        <div className="icon"><MdOutlineMailOutline className="mx-2 fs-3 text-primary" /></div>
                        <div className="ms-4 fs-5 text">{t('page.fences')}</div>
                        <div className="m-auto"></div>
                        <div className="icon"><IoIosArrowForward className="fs-1 mx-2 text-secondary" /></div>
                    </Button>
                </Link>) : <></>}
            </div>
        </div>
    )
}